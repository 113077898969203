import { S3Client, DeleteObjectCommand } from "@aws-sdk/client-s3";

const deleteFile = async (fileName) => {
  const s3Client = new S3Client({
    endpoint: `${process.env.REACT_APP_DO_ENDPOINT}`,
    forcePathStyle: false,
    region: `${process.env.REACT_APP_DO_REGION}`,
    credentials: {
      accessKeyId: `${process.env.REACT_APP_DO_ACCESS_KEY_ID}`,
      secretAccessKey: `${process.env.REACT_APP_DO_SPACES_SECRET}`,
    },
  });

  const params = {
    Bucket: process.env.REACT_APP_DO_BUCKET,
    Key: fileName,
  };

  try {
    await s3Client.send(new DeleteObjectCommand(params));
    console.log("File deleted successfully.");
  } catch (error) {
    console.error("Error deleting file:", error);
  }
};

export default deleteFile;
