import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";

import { MdAddCircleOutline } from "react-icons/md";
import { RxUpdate } from "react-icons/rx";

import axios from "../../../api/axios";
import { AWARD_DETAILS_CONFIG_URL } from "../../../api/api_routing_urls";

import showToast from "../../../utilities/notification/NotificationModal";

import AddAwardForm from "./addAwardForm.component";
import AwardList from "./awardList.component";
import Dashboard from "../../dashboard-components/dashboard.component";

const AwardConfig = () => {
  const [awardCompleteDetailsList, setAwardCompleteDetailsList] = useState([]);

  const [showAddForm, setShowAddForm] = useState(false);
  const [isAdd, setIsAdd] = useState(true);
  const [editAwardDetails, setEditAwardDetails] = useState({});

  const isEdit = Object.keys(editAwardDetails).length > 0 ? true : false;

  const getAwardCompleteDetailsList = async () => {
    try {
      const response = await axios.get(AWARD_DETAILS_CONFIG_URL);
      // console.log("Award with complete details List", { response });
      response.status === 200 &&
        setAwardCompleteDetailsList(response.data.awardCompleteDetails);
      response.status === 202 &&
        console.log("No awards found in the system.", "error");
    } catch (error) {
      console.error("getAwardCompleteDetailsList", error);
      if (!error?.response) {
        console.log("No Server Response");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.", "error");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getAwardCompleteDetailsList();
      setEditAwardDetails({});
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      <Dashboard sidebarType={"Super Admin"}>
        <ToastContainer />
        {showAddForm ? (
          <div className="flex flex-col mb-10">
            <div className="flex gap-2 items-center pb-5">
              {/* <div className="">
                {isAdd ? (
                  <MdAddCircleOutline size={18} color="green" />
                ) : (
                  <RxUpdate size={17} color="blue" />
                )}
              </div> */}
              <div className="text-primary font-semibold text-lg border-b border-primary">
                {isAdd ? <>Add Award</> : <>Update Award Details</>}
              </div>
            </div>

            <AddAwardForm
              getAwardCompleteDetailsList={getAwardCompleteDetailsList}
              editAwardDetails={editAwardDetails}
              setEditAwardDetails={setEditAwardDetails}
              setShowAddForm={setShowAddForm}
              setIsAdd={setIsAdd}
            />
          </div>
        ) : (
          <div className="grid grid-cols-1">
            <AwardList
              awardCompleteDetailsList={awardCompleteDetailsList}
              getAwardCompleteDetailsList={getAwardCompleteDetailsList}
              setEditAwardDetails={setEditAwardDetails}
              setShowAddForm={setShowAddForm}
              setIsAdd={setIsAdd}
            />
          </div>
        )}
      </Dashboard>
    </>
  );
};

export default AwardConfig;
