import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

import axios from "../../../api/axios";
import { MILESTONE_DETAILS_CONFIG_URL } from "../../../api/api_routing_urls";

import aboutusmilestonesbannerimage from "../../../assets/milestone.jpg";
import milestonediagram from "../../../assets/sikkimmilkmilestone.jpeg";
import blankmilestone from "../../../assets/milestone-nobg.png";

const AboutUsMilestones = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [milestoneCompleteDetailsList, setMilestoneCompleteDetailsList] =
    useState([]);

  const getMilestoneCompleteDetailsList = async () => {
    try {
      const response = await axios.get(MILESTONE_DETAILS_CONFIG_URL);
      // console.log("Milestone with complete details List", { response });
      response.status === 200 &&
        setMilestoneCompleteDetailsList(response.data.milestoneCompleteDetails);
      response.status === 202 &&
        console.log("No milestones found in the system.", "error");
    } catch (error) {
      console.error("getMilestoneCompleteDetailsList", error);
      if (!error?.response) {
        console.log("No Server Response");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.", "error");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getMilestoneCompleteDetailsList();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      <div>
        <div className="">
          <img
            src={aboutusmilestonesbannerimage}
            className="object-fill w-full  h-full"
          />
        </div>
      </div>
      <div className="w-[80%] mx-auto mt-4 py-10 grid grid-cols-1 lg:grid-cols-4">
        <div className="col-span-3">
          <div className="mt-6 lg:mt-12 text-2xl md:text-3xl text-gray-600 flex justify-center border-b border-gray-500 pb-4">
            Milestones
          </div>
          <div className="flex justify-center mt-6 md:mt-8">
            {/* <img
              src={milestonediagram}
              className="object-contain w-full h-full"
            /> */}
          </div>

          <div className="mt-10">
            {milestoneCompleteDetailsList?.length > 0 ? (
              <div>
                {milestoneCompleteDetailsList?.map((milestoneObj, index) => (
                  <div className="flex flex-col items-center justify-center">
                    {index % 2 === 0 ? (
                      <div className="grid grid-cols-3">
                        <div className="text-justify pr-5 lg:pr-0">
                          <p className="text-lg font-semibold">
                            {milestoneObj?.milestone_year}
                          </p>
                          <div className="text-xs text-gray-700 pt-1">
                            {milestoneObj?.milestone_description}
                          </div>
                        </div>

                        <div className="flex flex-col items-center">
                          <div className="relative border-8 rounded-full p-5 lg:p-8">
                            <img
                              src={blankmilestone}
                              className="h-28 lg:h-36"
                            />
                            <div className="absolute top-[40%] lg:top-[45%] left-[40%] lg:left-[45%] text-sm font-bold z-60">
                              {milestoneObj?.milestone_year}
                            </div>
                          </div>

                          <div className="h-[50vh] lg:h-[35vh] border-l border-gray-500 text-white">
                            |
                          </div>
                        </div>

                        <div></div>
                      </div>
                    ) : (
                      <div className="grid grid-cols-3">
                        <div></div>

                        <div className="flex flex-col items-center">
                          <div className="relative border-8 rounded-full p-5 lg:p-8">
                            <img
                              src={blankmilestone}
                              className="h-28 lg:h-36"
                            />
                            <div className="absolute top-[40%] lg:top-[45%] left-[40%] lg:left-[45%] text-sm font-bold z-60">
                              {milestoneObj?.milestone_year}
                            </div>
                          </div>

                          <div className="h-[50vh] lg:h-[35vh] border-l border-gray-500 text-white">
                            |
                          </div>
                        </div>

                        <div className="text-justify pl-5 lg:pl-0">
                          <p className="text-lg font-semibold">
                            {milestoneObj?.milestone_year}
                          </p>
                          <div className="text-xs text-gray-700 pt-1">
                            {milestoneObj?.milestone_description}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
                <div className="flex justify-center mr-1 text-gray-300 font-extrabold -mt-5">
                  ___
                </div>
              </div>
            ) : (
              <div className="flex justify-center text-sm text-gray-700 py-50">
                No milestones found.
              </div>
            )}
          </div>
          {/* 
          <div className="my-24 flex flex-col items-center justify-center">
            <div className="relative border-8 rounded-full p-8">
              <img src={blankmilestone} className="h-36" />
              <div className="absolute top-[45%] left-[45%] text-sm font-bold z-60">
                2023
              </div>
            </div>

            <div className="h-[30vh] border-l border-gray-500 text-white">
              |
            </div>

            <div className="relative border-8 rounded-full p-8">
              <img src={blankmilestone} className="h-36" />
              <div className="absolute top-[45%] left-[45%] text-sm font-bold z-60">
                2023
              </div>
            </div>
          </div> */}
        </div>

        <div className="col-span-1 lg:ml-10 mt-20 lg:mt-10">
          <div className="text-gray-600 mb-5 md:text-lg">About Us</div>
          <div className="flex flex-col  border border-gray-300">
            <Link to="/about-us-profile">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                Profile
              </div>
            </Link>
            <Link to="/about-us-mission">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                Mission
              </div>
            </Link>
            <Link to="/about-us-objectives">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                Objective
              </div>
            </Link>
            <Link to="/about-us-functions">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                Functions
              </div>
            </Link>
            <Link to="/about-us-board-of-directors">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                Board of Directors
              </div>
            </Link>
            <Link to="/about-us-smu-executives">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                SMU Executives
              </div>
            </Link>
            <Link to="/about-us-organisation-chart">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                Organization Chart
              </div>
            </Link>
            <Link to="/about-us-milestones">
              <div className="bg-[#1466A2] text-white text-sm  px-2 py-2 cursor-pointer">
                Milestones
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsMilestones;
