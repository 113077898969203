import React from "react";

export default function Button(props) {
  return (
    <button
      onClick={props.onClick}
      className={props.className}
      aria-label={props.aria_label}
      type="button"
      props
    >
      {props.children}
    </button>
  );
}
