import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import { AiOutlineMail } from "react-icons/ai";
import { MdOutlineLocationOn } from "react-icons/md";
import { FaRegAddressBook } from "react-icons/fa";
import { GrFacebook } from "react-icons/gr";

import googleplayimage from "../../../../assets/googleplay.png";

// import axios from "axios";
// import useAxios from "../../hooks/useAxios";

const Footer = () => {
  return (
    <div className="px-20 bg-[#1466A2] mt-20 py-10">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-y-10 lg:gap-y-0 md:gap-x-16 lg:gap-x-20 text-white">
        <div className="flex-col">
          <div className="border-b-2 border-white pb-1">
            <div className="flex items-center justify-start gap-2">
              <div>
                <MdOutlineLocationOn />
              </div>
              <div>Address</div>
            </div>
          </div>
          <div className="pt-5">
            <p className="text-sm">
              The Sikkim Milk Co-operative Milk Producer's Union Limited
            </p>
            <p className="text-sm pt-1">5th Mile, Tadong</p>
            <p className="text-sm pt-1">Sikkim, 737102</p>
          </div>
        </div>

        <div className="flex-col">
          <div className="border-b-2 border-white pb-1">
            <div className="flex items-center justify-start gap-2">
              <div>
                <FaRegAddressBook />
              </div>
              <div>Contact</div>
            </div>
          </div>
          <div className="pt-5">
            <p className="text-sm">Ph No: +91-3592-231108 / 231218 / 231906</p>
            <p className="text-sm pt-1">Fax: +91-3592-232107</p>
          </div>
        </div>

        <div className="flex-col">
          <div className="border-b-2 border-white pb-1">
            <div className="flex items-center justify-start gap-2">
              <div>
                <AiOutlineMail />
              </div>
              <div>Email</div>
            </div>
          </div>
          <div className="pt-5">
            <p className="text-sm">milkgangtok@gmail.com</p>
          </div>
        </div>

        <div className="flex-col">
          <div className="border-b-2 border-white pb-1">
            <div className="flex items-center justify-start gap-2">
              <div>
                <GrFacebook />
              </div>
              <div>Facebook</div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-16 flex justify-center">
        <a
          href="https://play.google.com/store/search?q=sikkim%20milk%20mitra%20app&c=apps&hl=en-IN"
          target="_blank"
        >
          <img
            src={googleplayimage}
            className="h-10 rounded-md cursor-pointer"
          />
        </a>
      </div>
      <div className="pt-3 flex justify-center">
        <p className="text-white">Terms and Conditions</p>
      </div>
      <div className="flex justify-center items-center py-1">
        <p className="text-white text-xs xl:text-sm">
          ©{new Date().getFullYear()} All Rights Reserved Sikkim Milk Union
        </p>
      </div>
    </div>
  );
};

export default Footer;
