/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useEffect } from "react";

import axios from "../../../api/axios";
import { YOUTUBE_GALLERY_DETAILS_CONFIG_URL } from "../../../api/api_routing_urls";

const YoutubeGallery = () => {
  const [
    youtubeGalleryCompleteDetailsList,
    setYoutubeGalleryCompleteDetailsList,
  ] = useState([]);

  const getYoutubeGalleryCompleteDetailsList = async () => {
    try {
      const response = await axios.get(YOUTUBE_GALLERY_DETAILS_CONFIG_URL);
      // console.log("Youtube Gallery with complete details List", { response });
      if (response.status === 200) {
        setYoutubeGalleryCompleteDetailsList(
          response.data.youtubeGalleryCompleteDetails
        );
      }

      response.status === 202 &&
        console.log("No youtube gallery found in the system.", "error");
    } catch (error) {
      console.error("getYoutubeGalleryCompleteDetailsList", error);
      if (!error?.response) {
        console.log("No Server Response");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.", "error");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getYoutubeGalleryCompleteDetailsList();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <section className="m-5 md:m-10 lg:m-20">
      <div className="mb-10 text-center text-xl lg:text-2xl font-semibold text-primary underline underline-offset-4 uppercase">
        Our Videos
      </div>

      {youtubeGalleryCompleteDetailsList?.length > 0 && (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-5 gap-y-10">
          {youtubeGalleryCompleteDetailsList?.map((ytObj, index) => (
            <div key={index}>
              <YouTubeVideoCard
                videoLink={ytObj?.youtube_video_link}
                videoTitle={ytObj?.video_title}
              />
            </div>
          ))}
        </div>
      )}
    </section>
  );
};

export default YoutubeGallery;

const YouTubeVideoCard = ({ videoLink, videoTitle }) => {
  const opts = {
    height: "400",
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return (
    <section className="">
      <div className="text-xs font-semibold pt-4 text-gray-700">
        {videoTitle}
      </div>
    </section>
  );
};
