import React from "react";

import Iframe from "react-iframe";

const ContactUs = () => {
  return (
    <>
      <div className="w-full">
        {/* Map Large Devices */}
        <div className="iframe-container-large hidden md:block">
          <div className="overflow-x-scroll lg:overflow-x-hidden">
            <Iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14179.26513394366!2d88.5972493!3d27.3189377!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39e6a502d13bc3ed%3A0x8b60ed09d4b63bf3!2sSikkim%20Milk%20Union%20Ltd!5e0!3m2!1sen!2sin!4v1690457209348!5m2!1sen!2sin"
              width="100%"
              height="300"
              className="responsive-iframe"
              style="border:0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            />
          </div>
        </div>

        {/* Map Small Devices */}
        <div className="iframe-container-small block md:hidden">
          <div className="overflow-x-scroll lg:overflow-x-hidden">
            <Iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14179.26513394366!2d88.5972493!3d27.3189377!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39e6a502d13bc3ed%3A0x8b60ed09d4b63bf3!2sSikkim%20Milk%20Union%20Ltd!5e0!3m2!1sen!2sin!4v1690457209348!5m2!1sen!2sin"
              width="600"
              height="450"
              className="responsive-iframe"
              style="border:0;"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            />
          </div>
        </div>
      </div>

      <div className="w-[80%] mx-auto mt-4 py-10 grid grid-cols-1 lg:grid-cols-3">
        {/* Contact Form */}
        <div className="col-span-2">
          <div className="mt-6 lg:mt-12 mb-10 text-3xl text-gray-600 flex justify-center border-b pb-4">
            Contact Us
          </div>
          <div className="lg:pr-10">
            <form
              //   action="https://formspree.io/f/mzbqdzwv"
              //   method="POST"
              autocomplete="off"
              className="p-0 flex flex-col justify-center"
            >
              <div className="flex flex-col">
                <label
                  for="name"
                  className="text-base font-semibold text-gray-600"
                >
                  Name
                </label>
                <input
                  type="name"
                  name="name"
                  id="name"
                  placeholder="Full Name"
                  className="w-full py-1 px-3 rounded-sm bg-white dark:bg-gray-800 border border-gray-400 dark:border-gray-700 text-gray-800 focus:border-primary focus:outline-none"
                />
              </div>

              <div class="flex flex-col mt-4">
                <label
                  for="email"
                  className="text-base font-semibold text-gray-600"
                >
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email ID"
                  className="w-full py-1 px-3 rounded-sm bg-white dark:bg-gray-800 border border-gray-400 dark:border-gray-700 text-gray-800 font-semisemibold focus:border-primary focus:outline-none"
                />
              </div>

              <div class="flex flex-col mt-4">
                <label
                  for="tel"
                  className="text-base font-semibold text-gray-600"
                >
                  Number
                </label>
                <input
                  type="tel"
                  name="tel"
                  id="tel"
                  placeholder="Contact Number"
                  className="w-full py-1 px-3 rounded-sm bg-white dark:bg-gray-800 border border-gray-400 dark:border-gray-700 text-gray-800 font-semisemibold focus:border-primary focus:outline-none"
                />
              </div>

              <div class="flex flex-col mt-4">
                <label
                  for="comment"
                  className="text-base font-semibold text-gray-600"
                >
                  Comments
                </label>
                <textarea
                  rows={2}
                  type="text"
                  name="comment"
                  id="comment"
                  placeholder="Enter your remarks"
                  className="w-full py-1 resize-none px-3 rounded-sm bg-white dark:bg-gray-800 border border-gray-400 dark:border-gray-700 text-gray-800 focus:border-primary focus:outline-none"
                />
              </div>
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="mt-8 mb-10 md:mb-16 lg:mb-0 w-full bg-[#1466A2] bg-opacity-90 text-white font-semibold py-1 px-6 rounded-sm hover:bg-opacity-95 transition ease-in-out duration-300"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="lg:mt-24 lg:ml-20 flex flex-col gap-y-6">
          <div className="flex-col px-4 py-5 border border-gray-300">
            <div className="text-gray-600 font-semibold text-sm md:text-base">
              The Sikkim Milk Co-operative Milk Producer's Union Limited
            </div>
            <div className="text-gray-600 text-sm md:text-base">
              5th Mile, Tadong, Gangtok, Sikkim
            </div>
            <div className="text-gray-600 text-sm md:text-base">
              PIN Code: 737102
            </div>
          </div>

          <div className="flex-col px-4 py-5 border border-gray-300">
            <div className="text-gray-600 font-semibold text-sm md:text-base">
              Phone
            </div>
            <div className="text-gray-600 text-sm md:text-base">
              03592-231108 / 231218 / 231906
            </div>
            <div className="text-gray-600 text-sm md:text-base">Mobile</div>
            <div className="text-gray-600 text-sm md:text-base">
              78783838383
            </div>
          </div>

          <div className="flex-col px-4 py-5 border border-gray-300">
            <div className="text-gray-600 font-semibold text-sm md:text-base">
              Email
            </div>
            <div className="text-gray-600 text-sm md:text-base">
              milkgangtok@gmail.com
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
