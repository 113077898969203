import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "../../../api/axios";
import { GALLERY_DETAILS_CONFIG_URL } from "../../../api/api_routing_urls";
import { IoCloseSharp } from "react-icons/io5";
import ImageDropzone from "../../../reusable-components/inputs/ImageDropzone/ImageDropzone";
import uploadFileController from "../../../utilities/uploadFiles/uploadFileController";
import deleteFile from "../../../utilities/uploadFiles/deleteFileController";
import Button from "../../../reusable-components/buttons/Button";
import SubmitButton from "../../../reusable-components/buttons/SubmitButton";
import Input from "../../../reusable-components/inputs/InputTextBox/Input";
import { button, cancel_button } from "../../../theme/lightTheme";
import showToast from "../../../utilities/notification/NotificationModal";

const AddGalleryForm = ({
  getGalleryCompleteDetailsList,
  editGalleryDetails,
  setEditGalleryDetails,
  setShowAddForm,
  setIsAdd,
}) => {
  const [galleryWithImageDetailsList, setGalleryWithImageDetailsList] =
    useState([]);

  const [imageArr, setImageArr] = useState([]);
  const [changePhoto, setChangePhoto] = useState(false);
  const [noImagesErrMessage, setNoImagesErrMessage] = useState(false);
  const [imageDeleteId, setImageDeleteId] = useState(null);
  //   const [addToGalleryChecked, setAddToGalleryChecked] = useState(false);

  const defaultPrefix = "https://files.sikkimmilk.com/media/";

  const getGalleryWithImageDetailsList = async (gallery_id) => {
    try {
      const response = await axios.get(
        `${GALLERY_DETAILS_CONFIG_URL}/gallery/image`,
        {
          params: {
            gallery_id,
          },
        }
      );
      response.status === 200 &&
        setGalleryWithImageDetailsList(response.data.imageDetailsByGalleryId);
      response.status === 202 &&
        showToast("No album image found in the system.", "error");
    } catch (error) {
      console.error("getGalleryWithImageDetailsList", error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided.", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  const uploadFile = async (file) => {
    let fileUrl = "";

    try {
      await uploadFileController(file)
        .then((file_url) => {
          fileUrl = file_url;
        })
        .catch((err) => {
          fileUrl = "Error";
          console.error(err);
        });
      return fileUrl;
    } catch (error) {
      console.error(error);
      return "Error";
    }
  };

  const addGalleryAlbumImages = async () => {
    let file_url = "";
    let file_list = [];

    if (imageArr) {
      return await Promise.all(
        imageArr?.map(async (file) => {
          file_url = await uploadFile(file);
          await file_list.push(file_url);
        })
      ).then(async () => {
        return await file_list;
      });
    }
  };

  const submitGalleryAlbumImages = async () => {
    let fileUrlArr = "";

    let file_List = await addGalleryAlbumImages();

    fileUrlArr = JSON.stringify(file_List);

    return await fileUrlArr;
  };

  const onClickImageDelete = async (imgObj) => {
    try {
      let response = "";
      if (imgObj?.gallery_file_id) {
        response = await axios.post(
          `${GALLERY_DETAILS_CONFIG_URL}/image/delete`,
          {
            gallery_file_id: imgObj?.gallery_file_id,
          }
        );
      }

      if (response.status === 200) {
        getGalleryWithImageDetailsList(editGalleryDetails?.gallery_id);

        let delete_spaces_url = imgObj?.gallery_file_url?.replace(
          `${defaultPrefix}`,
          ""
        );
        await deleteFile(delete_spaces_url);
        console.log(
          "Image details has been deleted successfully from Spaces.",
          "success"
        );
      } else {
        console.log("Image details deletion from Spaces failed.", "error");
      }
    } catch (error) {
      console.log("Delete Module Error", error);
    } finally {
      setImageDeleteId(null);
    }
  };

  const isEdit = Object?.keys(editGalleryDetails)?.length > 0;

  const defaultValues = {
    gallery_id: !isEdit ? "" : editGalleryDetails?.gallery_id,
    album_name: !isEdit ? "" : editGalleryDetails?.album_name,
    is_added_to_gallery: !isEdit ? "" : editGalleryDetails?.is_added_to_gallery,
    file_url_arr: !isEdit ? "" : editGalleryDetails?.file_url_arr,
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    defaultValues: defaultValues,
  });

  const checkImageUploaded = () => {
    if (imageArr.length > 0) {
      setNoImagesErrMessage(false);
      return true;
    } else {
      setNoImagesErrMessage(true);
      return false;
    }
  };

  const onSubmit = async (data) => {
    try {
      let isImageUploaded = checkImageUploaded();
      let fileUrlArr = undefined;
      if (!isEdit) {
        fileUrlArr = await submitGalleryAlbumImages();
      }

      let sendDataObj = {
        album_name: data.album_name,
        is_added_to_gallery: false, // this same boolean from events section is used here to include event albums in gallery (assuming it as a forign key)
        file_url_arr: fileUrlArr || editGalleryDetails?.file_url_arr,
      };

      let response = "";
      if (!isEdit) {
        sendDataObj.created_by = "admin";
        if (isImageUploaded === true) {
          response = await axios.post(GALLERY_DETAILS_CONFIG_URL, sendDataObj);
        }
      } else {
        sendDataObj.updated_by = "admin";
        sendDataObj.gallery_id = editGalleryDetails?.gallery_id;

        response = await axios.post(
          `${GALLERY_DETAILS_CONFIG_URL}/update`,
          sendDataObj
        );
      }

      if (response.status === 200) {
        if (isEdit) {
          setEditGalleryDetails({});
          console.log(
            "Gallery details have been updated successfully.",
            "success"
          );
        } else {
          console.log(
            "Gallery details have been added successfully.",
            "success"
          );
        }
        getGalleryCompleteDetailsList();
      } else {
        if (isEdit) {
          console.log("Save Event Details Error:", response.data);
        } else {
          if (response.data.saveGalleryDetails.includes("unique_gallery")) {
            console.log(
              "Data already exists. Cannot insert duplicate data.",
              "error"
            );
          } else {
            console.log(
              "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
              "error"
            );
          }
        }
        return;
      }
      setShowAddForm(false);
      reset();
    } catch (error) {
      console.error("error", error);
      if (!error?.response) {
        console.log("No Server Response", "error");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.", "error");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  const onSubmitMoreImages = async () => {
    try {
      let fileUrlArr = undefined;

      fileUrlArr = await submitGalleryAlbumImages();

      let sendDataObj = {
        gallery_id: editGalleryDetails?.gallery_id,
        file_url_arr: fileUrlArr,
      };

      let response = "";

      response = await axios.post(
        `${GALLERY_DETAILS_CONFIG_URL}/gallery/image`,
        sendDataObj
      );

      if (response.status === 200) {
        getGalleryWithImageDetailsList(editGalleryDetails?.gallery_id);
        console.log(
          "More images for the given event have been added successfully.",
          "success"
        );
        setChangePhoto(false);
      } else {
        console.log(
          "Save More Images for the given Event Error:",
          response.data
        );

        return;
      }
    } catch (error) {
      console.error("error", error);
      if (!error?.response) {
        console.log("No Server Response", "error");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.", "error");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted && isEdit) {
      //   setAddToGalleryChecked(editEventDetails?.is_added_to_gallery);
      getGalleryWithImageDetailsList(editGalleryDetails?.gallery_id);
      setNoImagesErrMessage(false);
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <section>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          defaultName="album_name"
          register={register}
          name="Album name"
          required={true}
          pattern={null}
          errors={errors}
          placeholder="Enter a name for the image album"
          setError={setError}
          clearError={clearErrors}
          autoComplete="off"
          type="text"
          classes={`px-3 py-2 text-sm w-full mb-5`}
          onChangeInput={null}
          defaultValue={defaultValues.album_name}
          setValue={setValue}
        />

        <div className="flex justify-between  mt-5">
          <div className="flex items-center font-medium text-left text-gray-900 pl-1">
            Album Photos <span className="text-red-700">*</span>
          </div>
          {noImagesErrMessage && (
            <div className="text-red-700 text-xs">
              **This is a required field.
            </div>
          )}

          <div>
            {isEdit && (
              <>
                {!changePhoto ? (
                  <div
                    onClick={() => {
                      setChangePhoto(true);
                      // setChangePhoto((prev) => !prev);
                    }}
                    className="flex items-center gap-x-2 bg-primary py-1 px-3 rounded-md cursor-pointer"
                  >
                    <div className="text-white">Add Photos</div>

                    <div className="text-primary bg-white rounded-full h-4 w-4 flex justify-center items-center">
                      +
                    </div>
                  </div>
                ) : (
                  <div className="flex gap-x-2">
                    <div
                      onClick={() => {
                        onSubmitMoreImages();
                      }}
                      className="flex items-center gap-x-2 bg-primary py-1 px-3 cursor-pointer"
                    >
                      <div className="text-white">Add</div>
                    </div>

                    <div
                      onClick={() => {
                        setChangePhoto(false);
                      }}
                      className="flex items-center gap-x-2 border py-1 px-3 cursor-pointer"
                    >
                      <div className="text-black">Cancel</div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        {changePhoto === true ? (
          <>
            <ImageDropzone
              fieldTitle=""
              onChange={setImageArr}
              multiple={true}
              available_image={""}
            />
          </>
        ) : (
          <div>
            {isEdit ? (
              <div className="mt-3 border border-primary flex justify-center p-8">
                <div className="grid grid-cols-6 gap-3 pt-4">
                  {galleryWithImageDetailsList?.map((img, index) => (
                    <div key={index} className="relative">
                      <img src={img?.gallery_file_url} alt="Event Image" />

                      {galleryWithImageDetailsList.length > 1 && (
                        <div
                          className="absolute top-0 right-0 p-1 bg-primary text-white cursor-pointer"
                          onClick={() => {
                            onClickImageDelete(img);
                          }}
                        >
                          <IoCloseSharp color="white" />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div>
                <ImageDropzone
                  fieldTitle=""
                  onChange={setImageArr}
                  multiple={true}
                  available_image={""}
                />
              </div>
            )}
          </div>
        )}

        <div className="flex justify-between mt-10">
          <SubmitButton
            isSubmitting={isSubmitting}
            label={!isEdit ? "Submit" : "Update"}
            className={button}
          />
          <Button
            onClick={() => {
              setEditGalleryDetails({});
              setValue("", "");
              setShowAddForm(false);
              setIsAdd(true);
            }}
            aria-label="Cancel Gallery Details Update Button"
            className={cancel_button}
          >
            Cancel
          </Button>
        </div>
      </form>
    </section>
  );
};

export default AddGalleryForm;
