import React, { useState, Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";

import { YOUTUBE_GALLERY_DETAILS_CONFIG_URL } from "../../../api/api_routing_urls";
import axios from "../../../api/axios";

import { IoAddOutline } from "react-icons/io5";
import { SlOptionsVertical } from "react-icons/sl";
import { FiEdit, FiEye, FiTrash2 } from "react-icons/fi";

import Button from "../../../reusable-components/buttons/Button";
import { linkTextPrimary } from "../../../theme/lightTheme";

import DeleteModal from "../../../reusable-components/modals/DeleteModal";
import showToast from "../../../utilities/notification/NotificationModal";
import { linkTextDanger } from "../../../theme/lightTheme";

export default function MilestoneList({
  youtubeGalleryCompleteDetailsList,
  getYoutubeGalleryCompleteDetailsList,
  setEditYoutubeGalleryDetails,
  setShowAddForm,
  setIsAdd,
}) {
  // console.log({ youtubeGalleryCompleteDetailsList });

  const [showDelete, setShowDelete] = useState(false);
  const [youtubeGalleryDeleteId, setYoutubeGalleryDeleteId] = useState(null);

  const onClickEdit = (ytgallery) => {
    // console.log("youtube gallery received inside Edit: ", ytgallery);
    setEditYoutubeGalleryDetails(ytgallery);
    setShowAddForm(true);
    setIsAdd(false);
  };

  const onClickAdd = (ytgallery) => {
    setShowAddForm(true);
    setIsAdd(true);
  };

  const onClickDelete = async () => {
    // console.log("Received ID to delete: ", youtubeGalleryDeleteId);
    try {
      let response = "";
      if (youtubeGalleryDeleteId) {
        response = await axios.post(
          `${YOUTUBE_GALLERY_DETAILS_CONFIG_URL}/delete`,
          {
            video_id: youtubeGalleryDeleteId,
          }
        );
        setShowDelete(false);
      }

      if (response.status === 200) {
        console.log(
          "Youtube Gallery has been deleted successfully.",
          "success"
        );
        getYoutubeGalleryCompleteDetailsList();
      } else {
        console.log("Youtube Gallery deletion failed.", "error");
      }
    } catch (error) {
      console.log("Delete Module Error", error);
    } finally {
      setYoutubeGalleryDeleteId(null);
    }
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <>
      <div className="font-bold text-lg text-primary pb-1 border-b border-primary">
        Youtube Gallery Configuration
      </div>
      <div className="flex justify-end mt-5 mb-10">
        <div
          className="bg-primary cursor-pointer text-white text-xs rounded-sm px-2 py-1"
          onClick={() => onClickAdd()}
        >
          <div className="flex items-center justify-center gap-x-2">
            <div className="bg-white text-primary rounded-full">
              <IoAddOutline size={14} />
            </div>
            <div>Add Youtube Video Link</div>
          </div>
        </div>
      </div>

      <div className="overflow-x-none">
        <table className="w-full border">
          <thead className="bg-white text-sm">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left font-medium text-gray-900 uppercase tracking-wider"
              >
                Sl. No.
              </th>

              <th
                scope="col"
                className="px-6 py-3 text-left font-medium text-gray-900 uppercase tracking-wider"
              >
                Video Title
              </th>

              <th
                scope="col"
                className="px-6 py-3 text-left font-medium text-gray-900 uppercase tracking-wider"
              >
                Youtube Link
              </th>

              <th
                scope="col"
                className="px-6 py-3 text-left font-medium text-gray-900 uppercase tracking-wider"
              >
                Actions
              </th>
            </tr>
          </thead>

          <tbody className="bg-white divide-y divide-gray-100 text-gray-600">
            {youtubeGalleryCompleteDetailsList?.length > 0 ? (
              <>
                {youtubeGalleryCompleteDetailsList?.map((ytObj, index) => (
                  <tr key={index} className="border-t">
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      {index + 1}
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      {ytObj.video_title.slice(0, 50)}...
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      {ytObj.youtube_video_link.slice(0, 40)}...
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap text-sm relative">
                      {
                        <div>
                          <Menu as="div" className="block">
                            <div>
                              <Menu.Button>
                                <div className="ml-5 cursor-pointer text-primary">
                                  <SlOptionsVertical size={16} />
                                </div>
                              </Menu.Button>
                            </div>

                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="z-10 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                                <div className="py-1">
                                  <Menu.Item>
                                    {({ active }) => (
                                      <div
                                        onClick={() => onClickEdit(ytObj)}
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-gray-900"
                                            : "text-gray-700",
                                          "block px-4 py-2 text-sm cursor-pointer"
                                        )}
                                      >
                                        <div className="flex items-center gap-3 text-black hover:text-primary">
                                          <FiEdit size={16} />
                                          <p className="">View/Edit</p>
                                        </div>
                                      </div>
                                    )}
                                  </Menu.Item>

                                  <Menu.Item>
                                    {({ active }) => (
                                      <div
                                        onClick={() => {
                                          setYoutubeGalleryDeleteId(
                                            ytObj?.video_id || null
                                          );
                                          setShowDelete(true);
                                        }}
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-gray-900"
                                            : "text-gray-700",
                                          "block px-4 py-2 text-sm cursor-pointer"
                                        )}
                                      >
                                        <div className="flex items-center gap-3 text-black hover:text-primary">
                                          <FiTrash2 size={16} />
                                          <p className="">Delete</p>
                                        </div>
                                      </div>
                                    )}
                                  </Menu.Item>
                                </div>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        </div>
                      }
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <tr className="text-xs font-semibold border-t">
                <td colSpan={5} className="text-center py-6">
                  No youtube gallery configured in the system!
                </td>
              </tr>
            )}
          </tbody>

          <>
            <DeleteModal
              open={showDelete}
              setOpen={setShowDelete}
              message={
                "This youtube video entry will be deleted. Are you sure?"
              }
              onDelete={onClickDelete}
            />
          </>
        </table>
      </div>
    </>
  );
}
