import React from "react";
import { Link, NavLink } from "react-router-dom";
import {
  MdOutlineDashboard,
  MdOutlineSettingsInputComposite,
} from "react-icons/md";
// import SidebarIcons from "../dashboard/SidebarIcons";

export default function SystemAdminSidebar({
  // keep the pathname later to highlight the selected text
  pathname,
  sidebarExpanded,
  setSidebarExpanded,
}) {
  return (
    <ul className="pl-2">
      {/* Awards */}
      <div className="text-sm cursor-pointer text-white  flex gap-2 items-center">
        <div>{/* <MdOutlineSettingsInputComposite /> */}</div>
        <Link to="/sysadmin/awards-configuration" className="py-1">
          <span
            className={`shrink-0 ${
              pathname.includes("/awards-configuration")
                ? "text-slate-100 underline underline-offset-2"
                : "text-slate-300"
            } font-medium`}
          >
            Awards
          </span>
        </Link>
      </div>

      {/* Board of Directors */}
      <div className="text-sm cursor-pointer text-white  flex gap-2 items-center">
        <div>{/* <MdOutlineSettingsInputComposite /> */}</div>
        <Link to="/sysadmin/board-of-directors-configuration" className="py-1">
          <span
            className={`shrink-0 ${
              pathname.includes("/board-of-directors-configuration")
                ? "text-slate-100 underline underline-offset-2"
                : "text-slate-300"
            } font-medium`}
          >
            Board of Directors
          </span>
        </Link>
      </div>

      {/*Events */}
      <div className="text-sm cursor-pointer text-white  flex gap-2 items-center">
        <div>{/* <MdOutlineSettingsInputComposite /> */}</div>
        <Link to="/sysadmin/events-configuration" className="py-1">
          <span
            className={`shrink-0 ${
              pathname.includes("/events-configuration")
                ? "text-slate-100 underline underline-offset-2"
                : "text-slate-300"
            } font-medium`}
          >
            Events
          </span>
        </Link>
      </div>

      {/* Gallery */}
      <div className="text-sm cursor-pointer text-white  flex gap-2 items-center">
        <div>{/* <MdOutlineSettingsInputComposite /> */}</div>
        <Link to="/sysadmin/gallery-configuration" className="py-1">
          <span
            className={`shrink-0 ${
              pathname.includes("/gallery-configuration")
                ? "text-slate-100 underline underline-offset-2"
                : "text-slate-300"
            } font-medium`}
          >
            Gallery
          </span>
        </Link>
      </div>

      {/* Milestones */}
      <div className="text-sm cursor-pointer text-white  flex gap-2 items-center">
        <div>{/* <MdOutlineSettingsInputComposite /> */}</div>
        <Link to="/sysadmin/milestones-configuration" className="py-1">
          <span
            className={`shrink-0 ${
              pathname.includes("/milestones-configuration")
                ? "text-slate-100 underline underline-offset-2"
                : "text-slate-300"
            } font-medium`}
          >
            Milestones
          </span>
        </Link>
      </div>

      {/* Notices */}
      <div className="text-sm cursor-pointer text-white  flex gap-2 items-center">
        <div>{/* <MdOutlineSettingsInputComposite /> */}</div>
        <Link to="/sysadmin/notice-config" className="py-1">
          <span
            className={`shrink-0 ${
              pathname.includes("/notice-config")
                ? "text-slate-100 underline underline-offset-2"
                : "text-slate-300"
            } font-medium`}
          >
            Notices
          </span>
        </Link>
      </div>

      {/* Products */}
      <div className="text-sm cursor-pointer text-white  flex gap-2 items-center">
        <div>{/* <MdOutlineSettingsInputComposite /> */}</div>
        <Link to="/sysadmin/products-configuration" className="py-1">
          <span
            className={`shrink-0 ${
              pathname.includes("/products-configuration")
                ? "text-slate-100 underline underline-offset-2"
                : "text-slate-300"
            } font-medium`}
          >
            Products
          </span>
        </Link>
      </div>

      {/* SMU Executives */}
      <div className="text-sm cursor-pointer text-white  flex gap-2 items-center">
        <div>{/* <MdOutlineSettingsInputComposite /> */}</div>
        <Link to="/sysadmin/smu-executives-configuration" className="py-1">
          <span
            className={`shrink-0 ${
              pathname.includes("/smu-executives-configuration")
                ? "text-slate-100 underline underline-offset-2"
                : "text-slate-300"
            } font-medium`}
          >
            SMU Executives
          </span>
        </Link>
      </div>

      {/* Youtube Gallery */}
      <div className="text-sm cursor-pointer text-white  flex gap-2 items-center">
        <div>{/* <MdOutlineSettingsInputComposite /> */}</div>
        <Link to="/sysadmin/youtube-gallery-configuration" className="py-1">
          <span
            className={`shrink-0 ${
              pathname.includes("/youtube-gallery-configuration")
                ? "text-slate-100 underline underline-offset-2"
                : "text-slate-300"
            } font-medium`}
          >
            Youtube Gallery
          </span>
        </Link>
      </div>
    </ul>
  );
}
