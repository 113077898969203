import React, { useEffect } from "react";

import { Link } from "react-router-dom";

import aboutusmissionbannerimage from "../../../assets/mission.jpg";

const AboutUsMission = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div>
        <div className="">
          <img
            src={aboutusmissionbannerimage}
            className="object-fill w-full  h-full"
          />
        </div>
      </div>
      <div className="w-[80%] mx-auto mt-4 py-10 grid grid-cols-1 lg:grid-cols-4">
        <div className="col-span-3">
          <div className="mt-6 lg:mt-12 text-2xl md:text-3xl text-gray-600 flex justify-center border-b border-gray-500 pb-4">
            Mission
          </div>
          <div className="mt-8 text-xl md:text-2xl text-gray-600 leading-5 md:leading-7 font-serif italic font-light text-center">
            "Farmer's Prosperity Through Consumer Satisfaction"
          </div>
        </div>

        <div className="col-span-1 lg:ml-10 mt-20 lg:mt-10">
          <div className="text-gray-600 mb-5 md:text-lg">About Us</div>
          <div className="flex flex-col  border border-gray-300">
            <Link to="/about-us-profile">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                Profile
              </div>
            </Link>
            <Link to="/about-us-mission">
              <div className="bg-[#1466A2] text-white text-sm  px-2 py-2 cursor-pointer">
                Mission
              </div>
            </Link>
            <Link to="/about-us-objectives">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                Objective
              </div>
            </Link>
            <Link to="/about-us-functions">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                Functions
              </div>
            </Link>
            <Link to="/about-us-board-of-directors">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                Board of Directors
              </div>
            </Link>
            <Link to="/about-us-smu-executives">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                SMU Executives
              </div>
            </Link>
            <Link to="/about-us-organisation-chart">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                Organization Chart
              </div>
            </Link>
            <Link to="/about-us-milestones">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                Milestones
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsMission;
