import React, { useEffect } from "react";
import H2withIcon from "../../../reusable-components/headings/H2withIcon";
import BackButton from "../../../reusable-components/buttons/BackButton";
import { useForm } from "react-hook-form";
import useAxiosPrivate from "../../../authentication/hooks/useAxiosPrivate";
import showToast from "../../../utilities/notification/NotificationModal";
import SubmitButton from "../../../reusable-components/buttons/SubmitButton";
import Button from "../../../reusable-components/buttons/Button";
import { button, cancel_button } from "../../../theme/lightTheme";
import Input from "../../../reusable-components/inputs/InputTextBox/Input";
import TextArea from "../../../reusable-components/inputs/InputTextAreas/TextArea";
import { useState } from "react";
import RadioButtonforNotice from "../../../reusable-components/radiobutton/RadioButtonforNotice";
import PDFImageUploaderAWS from "../../../reusable-components/inputs/FileUploader/PDFUploaderAWS";
import { FaFilePdf, FaRegFilePdf } from "react-icons/fa";
import RichTextArea from "../../../reusable-components/richtexteditor/RichTextArea";
import ImageDropzone from "../../../reusable-components/inputs/ImageDropzone/ImageDropzone";
import axios from "../../../api/axios";
import { NOTICE_DETAILS_URL } from "../../../api/api_routing_urls";
import uploadFileController from "../../../utilities/uploadFiles/uploadFileController";
import { getFormattedDateInputDate } from "../../../utilities/dateFunctions/formatdate";

export default function AddNoticeForm({
  setShowAddForm,
  editNoticeDetails,
  setEditNoticeDetails,
  getNoticeDetails,
  editNoticeStatusDetails,
}) {
  //   const axiosPrivate = useAxiosPrivate();
  //   const { auth } = useAuth();
  //   const controller = new AbortController();

  const [noticePDFDoc, setNoticePDFDoc] = useState("");
  const [imageObj, setImageObj] = useState();
  const [selectedNoticeType, setSelectedNoticeType] = useState();

  const [noticeDoc, setNoticeDoc] = useState({});

  let isEdit =
    editNoticeDetails === undefined || editNoticeDetails === null
      ? false
      : Object?.keys(editNoticeDetails)?.length > 0
      ? true
      : false;

  let defaultValues = {
    notice_text: isEdit ? editNoticeDetails?.notice_text : "",
    publishedstartdate: !isEdit
      ? ""
      : editNoticeDetails?.notice_published_startdate,
    publishedenddate: !isEdit
      ? ""
      : editNoticeDetails?.notice_published_enddate,
    noticetitle: !isEdit ? "" : editNoticeDetails?.notice_title,
    noticedescription: !isEdit
      ? ""
      : editNoticeDetails?.notice_short_description,

    notice_pdf: !isEdit ? "" : "PDFName[0]",
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    defaultValues: defaultValues,
    mode: "onChange",
    criteriaMode: "all",
    shouldFocusError: true,
    // shouldUnregister: true,
  });

  function createFileObj(file_name, file_type, file_url) {
    let fileObj = {
      filename: file_name,
      filetype: file_type,
      fileurl: file_url,
    };
    return fileObj;
  }
  // for uploading pdf
  async function updateNoticeDocument() {
    let file_url = "";
    let file_list = [];

    if (noticePDFDoc) {
      file_url = await uploadFile(noticePDFDoc);
      file_list.push(createFileObj(noticePDFDoc?.name, "PDF", file_url));
    } else if (noticeDoc?.pdf_url) {
      file_list.push(
        createFileObj(
          noticeDoc?.pdf_url
            ?.split("/")
            [noticeDoc?.pdf_url?.split("/").length - 1].replace("%20", " "),
          "PDF",
          noticeDoc.pdf_url
        )
      );
    }
    if (imageObj) {
      file_url = await uploadFile(imageObj[0]);
      file_list.push(createFileObj(imageObj[0]?.name, "IMG", file_url));
    } else if (noticeDoc?.img_url) {
      file_list.push(
        createFileObj(
          noticeDoc?.img_url
            ?.split("/")
            [noticeDoc?.img_url?.split("/").length - 1].replace("%20", " "),
          "IMG",
          noticeDoc.img_url
        )
      );
    }

    return file_list;
  }

  const uploadFile = async (file) => {
    let fileUrl = "";

    try {
      await uploadFileController(file)
        .then((file_url) => {
          fileUrl = file_url;
        })
        .catch((err) => {
          fileUrl = "Error";
          console.error(err);
        });
      return fileUrl;
    } catch (error) {
      console.error(error);
      return "Error";
    }
  };

  const onSubmit = async (data) => {
    try {
      let response = "";

      let fileList = await updateNoticeDocument();

      let sendDataObj = {
        notice_docs_file: JSON.stringify(fileList),
        notice_published_startdate: data.publishedstartdate,
        notice_published_enddate: data.publishedenddate,
        notice_title: data.noticetitle,
        notice_short_description: data.noticedescription,
        notice_text: data.notice_text,
        notice_type: selectedNoticeType?.name,
        is_publish: false,
      };

      if (!isEdit) {
        sendDataObj.created_by = "admin";

        response = await axios.post(NOTICE_DETAILS_URL, sendDataObj);
      } else {
        sendDataObj.notice_id = editNoticeDetails?.notice_id;
        sendDataObj.updated_by = "admin";
        response = await axios.post(
          `${NOTICE_DETAILS_URL}/update`,
          sendDataObj
        );
        console.log("response", response);
      }
      if (response.status === 200) {
        console.log("ntoice has been updated");
        if (isEdit) {
          setEditNoticeDetails({});
          showToast("Notice has been updated successfully", "success");
        } else {
          getNoticeDetails();
          showToast("Notice has been added successfully", "success");
        }
        getNoticeDetails();
      } else {
        if (isEdit) {
          if (response.data.updateNoticeDetails.includes("unique_notice")) {
            showToast(
              "Notice already exists. Cannot insert duplicate bank",
              "error"
            );
          } else {
            showToast(
              "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
              "error"
            );
          }
        } else {
          //   if (
          //     response.data.saveBankBranchDetails.includes("unique_bankbranch")
          //   ) {
          //     showToast(
          //       "Bank Branch already exists. Cannot insert duplicate district",
          //       "error"
          //     );
          //   } else {
          //     showToast(
          //       "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          //       "error"
          //     );
          //   }
        }
        return;
      }
      setShowAddForm(false);
      setEditNoticeDetails({});
      reset();
    } catch (error) {
      if (!error?.response) {
        showToast("No Server Response", "error");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  useEffect(() => {
    setNoticePDFDoc(null);
    setImageObj(null);
  }, [selectedNoticeType]);

  useEffect(() => {
    if (editNoticeDetails?.doc_details) {
      let noticeDetails = editNoticeDetails;

      let doc_details = {};

      const pdf_data =
        Object?.values(noticeDetails?.doc_details).filter((obj) => obj.PDF)[0]
          ?.PDF || null;
      const img_data =
        Object?.values(noticeDetails?.doc_details).filter((obj) => obj.IMG)[0]
          ?.IMG || null;

      const pdf_url = (pdf_data && Object.values(pdf_data)[0]) || "";
      const img_url = (img_data && Object.values(img_data)[0]) || "";

      doc_details.pdf_url = pdf_url;
      doc_details.img_url = img_url;

      setNoticeDoc(doc_details);
    }
  }, [editNoticeDetails]);

  // console.log("editNoticeDetails", editNoticeDetails?.notice_text);

  return (
    <>
      <div className="flex  justify-between items-center px-4 mt-6 ">
        <H2withIcon>
          {isEdit ? (
            <>
              <span className="text-2xl font-semibold">Update Notice</span>
            </>
          ) : (
            <>
              <span className="text-2xl font-semibold">Add Notice</span>
            </>
          )}
        </H2withIcon>

        <BackButton
          label="back"
          onclick={() => {
            setShowAddForm(false);
            setEditNoticeDetails({});
          }}
        />
      </div>

      <section className="px-4 text-sm font-medium">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex justify-center gap-x-8 mt-6 pb-4 ">
            <div className="text-sm font-medium w-1/2 ">
              <Input
                defaultName="publishedstartdate"
                register={register}
                name="Published Start Date"
                required={true}
                pattern={null}
                errors={errors}
                placeholder="publsihed start date"
                setError={setError}
                clearError={clearErrors}
                autoComplete="off"
                type="date"
                classes={`rounded-sm px-3 py-2 text-sm w-full `}
                onChangeInput={null}
                defaultValue={getFormattedDateInputDate(
                  defaultValues.publishedstartdate
                )}
                setValue={setValue}
              />
            </div>

            <div className="w-1/2 text-sm font-medium ">
              <Input
                defaultName="publishedenddate"
                register={register}
                name="Published End Date"
                required={true}
                pattern={null}
                errors={errors}
                placeholder="published end date"
                setError={setError}
                clearError={clearErrors}
                autoComplete="off"
                type="date"
                classes={`rounded-sm px-3 py-2 text-sm w-full`}
                onChangeInput={null}
                defaultValue={getFormattedDateInputDate(
                  defaultValues.publishedenddate
                )}
                setValue={setValue}
              />
            </div>
          </div>

          <div className="pb-4">
            <Input
              defaultName="noticetitle"
              register={register}
              name="Notice Title"
              required={false}
              pattern={null}
              errors={errors}
              placeholder="Enter notice title"
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="text"
              classes={`rounded-sm px-3 py-2 text-sm w-full `}
              onChangeInput={null}
              defaultValue={defaultValues.noticetitle}
              setValue={setValue}
            />
          </div>

          <div className="pb-4">
            <TextArea
              defaultName="noticedescription"
              register={register}
              name="Notice Description"
              required={false}
              pattern={null}
              errors={errors}
              placeholder="Enter notice description"
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="text"
              classes={`rounded-sm px-3 py-2 text-sm w-full resize-none `}
              onChangeInput={null}
              defaultValue={defaultValues.noticedescription}
              setValue={setValue}
            />
          </div>

          <div className="py-4">
            <p className="py-2">Select Notice Type</p>
            <RadioButtonforNotice
              selectedNoticeType={selectedNoticeType}
              setSelectedNoticeType={setSelectedNoticeType}
              editNoticeDetails={editNoticeDetails}
            />
          </div>
          {selectedNoticeType?.name === "PDF" ? (
            <>
              <div className="flex justify-center border-2 border-dashed` border-[#1466A2] px-2 py-16 mt-6 rounded-md">
                <div className="flex flex-col gap-4  items-center">
                  <div className="pb-8">
                    <FaFilePdf size={44} color="red" />
                  </div>

                  <PDFImageUploaderAWS
                    register={register}
                    defaultName="notice_pdf"
                    name={`${
                      isEdit ? "Replace notice PDF " : "Add notice PDF"
                    }`}
                    required={false}
                    pattern={null}
                    placeholder="Document / Image"
                    type="file"
                    errors={errors}
                    classes={`rounded-sm px-1 py-1 text-sm w-full border-none outline-none text-center `}
                    setError={setError}
                    clearError={clearErrors}
                    file={noticePDFDoc}
                    setFile={setNoticePDFDoc}
                    setValue={setValue}
                    isEdit={isEdit}
                    oldFileOnEdit={noticeDoc.pdf_url}
                  />
                </div>
              </div>
            </>
          ) : null}
          {selectedNoticeType?.name === "Image" ? (
            <>
              <>
                <ImageDropzone
                  fieldTitle=""
                  onChange={setImageObj}
                  multiple={false}
                  available_image={noticeDoc.img_url || ""}
                />
              </>
            </>
          ) : null}
          {selectedNoticeType?.name === "Both Image and PDF" ? (
            <>
              <div className="flex justify-center border-2 border-dashed border-[#1466A2] px-2 py-16 mt-6 rounded-md">
                <div className="flex flex-col gap-4  items-center">
                  <div className="pb-8">
                    <FaFilePdf size={44} color="red" />
                  </div>

                  <PDFImageUploaderAWS
                    register={register}
                    defaultName="notice_pdf"
                    name={`${
                      isEdit && editNoticeDetails?.notice_pdf
                        ? "Replace notice PDF "
                        : "Add notice PDF"
                    }`}
                    required={false}
                    pattern={null}
                    placeholder="Document / Image"
                    type="file"
                    errors={errors}
                    classes={`rounded-sm px-1 py-1 text-sm w-full border-none outline-none text-center`}
                    setError={setError}
                    clearError={clearErrors}
                    file={noticePDFDoc}
                    setFile={setNoticePDFDoc}
                    setValue={setValue}
                    isEdit={isEdit}
                    oldFileOnEdit={isEdit ? noticeDoc.pdf_url : ""}
                  />
                </div>
              </div>

              <>
                <ImageDropzone
                  fieldTitle=""
                  onChange={setImageObj}
                  multiple={false}
                  available_image={noticeDoc.img_url || ""}
                />
              </>
            </>
          ) : null}
          {selectedNoticeType?.name === "Text" ? (
            <>
              <div className="mt-6">
                <RichTextArea
                  defaultName={"notice_text"}
                  register={register}
                  name={"Notice Text"}
                  classes={"w-full py-2 "}
                  clearError={clearErrors}
                  setError={setError}
                  setValue={setValue}
                  data={defaultValues.notice_text}
                  errors={errors}
                  pattern={null}
                  required={null}
                />
              </div>
            </>
          ) : null}

          <div className="flex justify-end gap-4 mt-8">
            <SubmitButton
              isSubmitting={isSubmitting}
              label={!isEdit ? "Submit" : "Update"}
              className={button}
            />
            <Button
              onClick={() => {
                //   setEditDistrictDetails({});
                //   setValue("districtname", "");
                setShowAddForm(false);
              }}
              aria-label="Cancel "
              className={cancel_button}
            >
              Cancel
            </Button>
          </div>
        </form>
      </section>
    </>
  );
}
