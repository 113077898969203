import React from "react";

const TableWrapper = (props) => {
  return (
    <div className="flex flex-col">
      <div className=" overflow-x-auto">
        <div className="align-middle inline-block min-w-full">
          <div className="border border-gray-300">
            <table className="min-w-full divide-y divide-gray-200">
              {props.children}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableWrapper;
