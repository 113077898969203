import React from "react";
import ContainerShadow from "../reusable-components/containers/ContainerShadow";
import LoginForm from "./LoginForm";
import Logo from "../assets/sikkimmilklogo.png";
import LoginFormUsingCookie from "./loginFormUsingCookie.componenet";

const Login = () => {
  return (
    <>
      <div className="h-screen flex flex-col justify-center items-center bg-blue-50">
        <div className="w-11/12 md:w-4/6 lg:w-2/4 xl:w-2/6 2xl:w-2/6">
          <img src={Logo} width="80" height="80" className="mx-auto mb-6" />
          <ContainerShadow>
            <div className="text-center pb-4">
              <p className="text-xl font-medium text-primary ">
                Login for SMU-CMS
              </p>
              <p className="text-slate-500 text-sm">
                Enter your credentials to access your account
              </p>
            </div>
            {/* <LoginForm /> */}
            <LoginFormUsingCookie />
          </ContainerShadow>
        </div>
      </div>
    </>
  );
};

export default Login;
