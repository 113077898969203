/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { getFormattedDateDDMMMYYYYDate } from "../../../utilities/dateFunctions/formatdate";
import GenericModal from "../../../reusable-components/generic_image_modal.component";

import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";

const ViewEventDetails = ({
  selectedEvent,
  setSelectedEvent,
  setShowEventDetails,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [clickedImage, setClickedImage] = useState();
  const [imageIndex, setImageIndex] = useState();

  let imgArr = selectedEvent?.images_list;

  const onImageClickHandler = (index) => {
    setImageIndex(index);
    setShowModal(true);
    setClickedImage(imgArr[index]);
  };

  const onCloseClickHandler = () => {
    setShowModal(false);
    setClickedImage({});
  };

  const onNextClickHandler = (imageIndex) => {
    let index = imageIndex;
    if (imageIndex < imgArr?.length - 1) {
      index += 1;
      setImageIndex(index);
    } else {
      index = 0;
      setImageIndex(index);
    }
    setClickedImage(imgArr[index]);
  };

  const onPreviousClickHandler = (imageIndex) => {
    let index = imageIndex;
    if (imageIndex > 0) {
      index -= 1;
      setImageIndex(index);
    } else {
      index = imgArr?.length - 1;
      setImageIndex(index);
    }

    setClickedImage(imgArr[index]);
  };

  useEffect(() => {
    setShowModal(false);
    setClickedImage({});
    setImageIndex(null);
  }, []);

  return (
    <section className="m-1 md:m-5 lg:m-20">
      <div className="mb-16 lg:mb-28 grid grid-cols-2 md:grid-cols-3">
        <div className="md:col-start-2 text-xl lg:text-2xl font-semibold text-primary underline underline-offset-4 uppercase">
          Events
        </div>

        <div className="col-span-1 flex justify-end">
          <div
            onClick={() => {
              setSelectedEvent(null);
              setShowEventDetails(false);
            }}
            className="py-1 px-3 md:px-5 border border-primary rounded-md text-xs lg:text-sm cursor-pointer"
          >
            Back
          </div>
        </div>
      </div>

      <div className="font-bold flex flex-col md:flex-row gap-x-1 mb-5 text-sm lg:text-base">
        <div>{selectedEvent?.event_name} </div>

        <div>
          ({getFormattedDateDDMMMYYYYDate(selectedEvent?.event_start_date)} -{" "}
          {getFormattedDateDDMMMYYYYDate(selectedEvent?.event_end_date)})
        </div>
      </div>

      <div className="text-sm font-medium text-slate-700 pb-6 ">
        <div>{selectedEvent?.event_description} </div>
      </div>

      <>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 md:gap-8">
          {selectedEvent?.images_list?.map((imgObj, index) => (
            <img
              key={index}
              src={imgObj?.file_url}
              onClick={() => onImageClickHandler(index)}
              className="cursor-pointer h-[30vh] lg:h-[35vh] w-full object-cover"
            />
          ))}
        </div>
      </>

      <>
        {showModal && (
          <>
            <GenericModal
              open={showModal}
              setOpen={setShowModal}
              clearData={setClickedImage}
              isAdd={true}
              isLarge={true}
            >
              <div className="relative">
                <div
                  onClick={() => onCloseClickHandler()}
                  className="flex justify-end text-white cursor-pointer text-lg"
                >
                  x
                </div>

                <img
                  src={clickedImage?.file_url}
                  className="object-contain h-[60vh] md:h-[70vh] w-[100vh]"
                />

                {/* <div
                  onClick={() => onCloseClickHandler()}
                  className="absolute top-4 md:top-1 right-0 md:right-4 text-white cursor-pointer text-lg"
                >
                  x
                </div> */}

                <div
                  onClick={() => onPreviousClickHandler(imageIndex)}
                  className="absolute top-[50%] left-4 cursor-pointer"
                >
                  <FaChevronLeft size={30} color="white" />
                </div>

                <div
                  onClick={() => onNextClickHandler(imageIndex)}
                  className="absolute top-[50%] right-4 cursor-pointer"
                >
                  <FaChevronRight size={30} color="white" />
                </div>
              </div>
            </GenericModal>
          </>
        )}
      </>
    </section>
  );
};

export default ViewEventDetails;
