import React, { useEffect, useState, Fragment } from "react";
import Error from "../../outputs/Error";
import { Document, Page } from "react-pdf";
import { fileTypeChecker } from "../../../utilities/validateDocuments/validateDocument";
import { fileNameExtension } from "../../../utilities/constants/regularexpressions";

export default function PDFImageUploaderAWS({
  register,
  defaultName,
  name,
  required,
  pattern,
  errors,
  classes,
  setError,
  clearError,
  file,
  setFile,
  setValue,
  isEdit,
  oldFileOnEdit,
  ...rest
}) {
  const { onChange, ...props } = register(defaultName, {
    required: { value: required, message: `${name} is required` },
    pattern: pattern,
  });

  const validateAndSetPDFFile = async (file) => {
    try {
      const fileType = await fileTypeChecker(file);

      if (fileType !== "unknown" && isPDFFileNameValid(file.name)) {
        setFile(file);
        if (setValue) {
          // setValue(defaultName, file); // Set the form control value
        } else {
          console.error("setValue is not defined.");
        }
      }
    } catch (error) {
      console.error("Invalid File:", error);
    }
  };

  const isPDFFileNameValid = (fileName) => {
    return fileNameExtension.test(fileName);
  };

  const onChangePDF = async (e) => {
    try {
      const selectedFile = e.target.files[0];

      if (!selectedFile) {
        return;
      }

      await validateAndSetPDFFile(selectedFile);
    } catch (error) {
      console.error("Some error", error);
    }
  };

  return (
    <section className="flex flex-col max-full mb-2 justify-center text-sm">
      {isEdit ? (
        <>
          {" "}
          {oldFileOnEdit !== "" && (
            <a href={oldFileOnEdit} target="_blank">
              <p className="text-primary font-medium mb-9">
                Previous Uploaded:{" "}
                {oldFileOnEdit
                  ?.split("/")
                  [oldFileOnEdit?.split("/").length - 1].replace("%20", " ")}
              </p>
            </a>
          )}
        </>
      ) : (
        <></>
      )}

      <label className="font-medium text-left">
        {name} {required && <span className="text-red-700">*</span>}
      </label>

      {/* <input
        type="file"
        id={defaultName}
        onChange={(e) => {
          onChangePDF(e);
          clearError(defaultName);

          if (required && e.target.value === "") {
            setError(defaultName, {
              type: "required",
              message: `${name} is required`,
            });
          }

          onChange(e);
        }}
        // defaultValue={file !== "" ? dataURLtoFile(file) : null}
        // accept="application/pdf"
        {...props}
        {...rest}
        className={`${classes} bg-green-50 p-1 border ${
          errors[defaultName] ? "border-red-700" : "border-slate-300"
        }`}
      /> */}

      <input
        type="file"
        id={defaultName}
        onChange={(e) => {
          onChangePDF(e);
          clearError(defaultName);

          if (required && e.target.value === "") {
            setError(defaultName, {
              type: "required",
              message: `${name} is required`,
            });
          }

          onChange(e);
        }}
        accept=".pdf" // Specify that only PDF files are allowed
        {...props}
        {...rest}
        className={`${classes} bg-green-50 p-1 border ${
          errors[defaultName] ? "border-red-700" : "border-slate-300"
        }`}
      />

      {errors[defaultName] && (
        <Error
          classes="flex flex-row gap-1 max-w-sm w-full mt-2"
          message={`${name} is required`}
        />
      )}
    </section>
  );
}
