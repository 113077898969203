import React, { useEffect } from "react";

import { Link } from "react-router-dom";

import dairyingbannerimage from "../../../assets/dairying.jpg";
import facilitiesdairytable from "../../../assets/dairychart.jpg";
import arrowimg from "../../../assets/arrow.png";

const FacilitiesDairy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div>
        <div className="">
          <img
            src={dairyingbannerimage}
            className="object-fill w-full  h-full"
          />
        </div>
      </div>
      <div className="w-[80%] mx-auto mt-4 py-10 grid grid-cols-1 lg:grid-cols-4">
        <div className="col-span-3">
          <div className="mt-6 lg:mt-12 text-2xl md:text-3xl text-gray-600 flex justify-center border-b border-gray-500 pb-4">
            Dairying in Sikkim
          </div>
          <div className="mt-4 text-xs md:text-sm text-gray-600 leading-5 md:leading-7 text-justify">
            <ul className="list-disc">
              <li className="text-green-500 py-3">
                <span className="text-gray-700">
                  Procurement, processing and marketing of milk began in 1977
                  with the establishment of Sikkim Livestock Development (SLDC).
                </span>
              </li>

              <li className="text-green-500 py-3">
                <span className="text-gray-700">
                  In 1978 the newly merged Government of Sikkim formally
                  requested the NDDB to extend Operation Flood program to the
                  state of Sikkim also in order that the dairy farmers of the
                  state also receive benefits from World's largest rural
                  development program, the Operation Flood.
                </span>
              </li>

              <li className="text-green-500 py-3">
                <span className="text-gray-700">
                  The NDDB sends its spearhead team for survey and submits
                  reports to the State Government with recommendation that
                  dairying as commercial enterprise on co- operative lines could
                  start in the three districts only viz. East, West, and South.
                </span>
              </li>

              <li className="text-green-500 py-3">
                <span className="text-gray-700">
                  North Sikkim being sparsely populated and there being no
                  substantial cattle population was left out of the scope.
                </span>
              </li>

              <li className="text-green-500 py-3">
                <span className="text-gray-700">
                  Sikkim Co- operative Milk Producers' Union (Sikkim Milk
                  Union)forms in July, 1980.
                </span>
              </li>

              <li className="text-green-500 py-3">
                <span className="text-gray-700">
                  All milk business of SLDC and the assets of Animal Husbandry
                  Dept. relating to milk business get transferred to Sikkim Milk
                  Union.
                </span>
              </li>

              <li className="text-green-500 py-3">
                <span className="text-gray-700">
                  Two milk plants one at Gangtok (10,000 LPD) and another at
                  Jorethang (5000LPD) were set up to turn key basis by the NDDB
                  with the financial assistance from Indian Diary Corporation on
                  70:30 grant and loan basis.
                </span>
              </li>

              <li className="text-green-500 py-3">
                <span className="text-gray-700">
                  Jorethang Milk Plant becomes operational from July 1981.
                </span>
              </li>

              <li className="text-green-500 py-3">
                <span className="text-gray-700">
                  Gangtok Milk Plant becomes operational from November 1982.
                </span>
              </li>

              <li className="text-green-500 py-3">
                <span className="text-gray-700">
                  An IMCU of 4,000 LPD Capacity was installed at Gyalshing in
                  1990 to enable to procure milk of remote villages of Yuksom
                  and Uttarey areas of West District.
                </span>
              </li>
            </ul>
          </div>

          {/* <div className="text-green-500 text-sm md:text-base flex justify-center mt-2">
            Society Organised monthly Summary (Gangtok Unit) for the Month of
            May
          </div> */}

          {/* <div className="flex justify-center mt-6 md:mt-4">
            <img
              src={facilitiesdairytable}
              className="object-fill w-full h-full"
            />
          </div> */}
        </div>

        <div className="col-span-1 lg:ml-10 mt-20 lg:mt-10">
          <div className="text-gray-600 mb-5 md:text-lg">Facilities</div>
          <div className="flex flex-col pt-2 border border-gray-300">
            <Link to="/facilities-head-office">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                Head Office
              </div>
            </Link>
            <Link to="/facilities-regional-office">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                Regional Office
              </div>
            </Link>
            <Link to="/facilities-plant">
              <div className="text-[#1466A2] text-sm  px-2 py-2 cursor-pointer">
                Plant
              </div>
            </Link>
            <Link to="/facilities-dairy-and-co-operative-societies">
              <div className="bg-[#1466A2] text-white text-sm  px-2 py-2 cursor-pointer">
                Dairy and Co-operative Societies
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default FacilitiesDairy;
