import React, { useState } from "react";

import { AWARD_DETAILS_CONFIG_URL } from "../../../api/api_routing_urls";
import axios from "../../../api/axios";

import { IoAddOutline } from "react-icons/io5";

import CMSAwardCard from "../../../reusable-components/cms-award-card/cmsAwardCard.component";

import Button from "../../../reusable-components/buttons/Button";
import { linkTextPrimary } from "../../../theme/lightTheme";

import DeleteModal from "../../../reusable-components/modals/DeleteModal";
import showToast from "../../../utilities/notification/NotificationModal";
import { linkTextDanger } from "../../../theme/lightTheme";

export default function AwardList({
  awardCompleteDetailsList,
  getAwardCompleteDetailsList,
  setEditAwardDetails,
  setShowAddForm,
  setIsAdd,
}) {
  // console.log({ awardCompleteDetailsList });

  const [showDelete, setShowDelete] = useState(false);
  const [awardDeleteId, setAwardDeleteId] = useState(null);

  const onClickEdit = (award) => {
    // console.log("award received inside Edit: ", award);
    setEditAwardDetails(award);
    setShowAddForm(true);
    setIsAdd(false);
  };

  const onClickAdd = (award) => {
    setShowAddForm(true);
    setIsAdd(true);
  };

  const onClickDelete = async () => {
    // console.log("Received ID to delete: ", awardDeleteId);
    try {
      let response = "";
      if (awardDeleteId) {
        response = await axios.post(`${AWARD_DETAILS_CONFIG_URL}/delete`, {
          award_id: awardDeleteId,
        });
        setShowDelete(false);
      }

      if (response.status === 200) {
        console.log("Award has been deleted successfully.", "success");
        getAwardCompleteDetailsList();
      } else {
        console.log("Award deletion failed.", "error");
      }
    } catch (error) {
      console.log("Delete Module Error", error);
    } finally {
      setAwardDeleteId(null);
    }
  };

  return (
    <>
      <div className="font-bold text-lg text-primary pb-1 border-b border-primary">
        Awards Configuration
      </div>
      <div className="flex justify-end mt-5 mb-10 mr-10">
        <div
          className="bg-primary cursor-pointer text-white text-xs rounded-sm px-2 py-1"
          onClick={() => onClickAdd()}
        >
          <div className="flex items-center justify-center gap-x-2">
            <div className="bg-white text-primary rounded-full">
              <IoAddOutline size={14} />
            </div>
            <div>Add Award</div>
          </div>
        </div>
      </div>

      <div>
        {awardCompleteDetailsList?.length > 0 ? (
          <div className="grid grid-cols-3 gap-10">
            {awardCompleteDetailsList?.map((awardObj) => (
              <div>
                <CMSAwardCard
                  awardObj={awardObj}
                  onClickEdit={onClickEdit}
                  setAwardDeleteId={setAwardDeleteId}
                  setShowDelete={setShowDelete}
                  onClickDelete={onClickDelete}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className="flex justify-center text-sm text-gray-700 py-20">
            No awards configured in the system.
          </div>
        )}
      </div>

      <>
        <DeleteModal
          open={showDelete}
          setOpen={setShowDelete}
          message={"This award will be deleted. Are you sure?"}
          onDelete={onClickDelete}
        />
      </>
    </>
  );
}
