import React, { useEffect, useRef, useState } from "react";
import "./Navbar.css";
import { AnimatePresence, motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";

import {
  BsFillBuildingFill,
  BsFillPersonFill,
  BsFillTelephoneFill,
  BsChevronDown,
} from "react-icons/bs";

import { FaPeopleLine, FaProductHunt } from "react-icons/fa6";
import { AiFillHome, AiOutlineHome } from "react-icons/ai";
import { HiUserGroup } from "react-icons/hi";

import NavHeaderLogo from "../../../../assets/smunavheaderlogo.jpg";

import { Disclosure } from "@headlessui/react";
import { MdPhotoSizeSelectActual } from "react-icons/md";

export default function Navbar() {
  const [open, setOpen] = useState(false);
  const [aboutUsCaret, setAboutUsCaret] = useState(false);
  const [facilitiesCaret, setFacilitiesCaret] = useState(false);

  const [showMenu, setShowMenu] = useState(false);

  const location = useLocation();

  const wrapperRef = useRef(null);

  const closeMenu = () => {
    setAboutUsCaret(false);
    setFacilitiesCaret(false);
    setOpen(false);
  };

  const itemVariants = {
    closed: {
      opacity: 0,
    },
    open: { opacity: 1 },
  };

  const sideVariants = {
    closed: {
      transition: {
        staggerChildren: 0.05,
        staggerDirection: -1,
      },
    },
    open: {
      transition: {
        staggerChildren: 0.1,
        staggerDirection: 1,
      },
    },
  };

  const onMenuClickHandler = () => {
    setShowMenu(!showMenu);
    const btn = document.getElementById("menu-btn");
    btn.classList.toggle(`open`);
  };

  const onNavClickHandler = () => {
    setShowMenu(false);
    const btn = document.getElementById("menu-btn");
    btn.classList.toggle(`open`);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        closeMenu();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const handleSkipToMainContent = () => {
    window.scroll({
      top: 400,
      behavior: "smooth",
    });
  };

  return (
    <div ref={wrapperRef} className=" font-sans   sticky-top ">
      {/* ----------------------------------------------------MOBILE NAVBAR START-------------------------------------------------  */}
      <section id="nav-bar" className="sticky-top   ">
        <div className=" flex items-center md:hidden">
          <div>
            <img src={NavHeaderLogo} />
          </div>
          <div className="lg:hidden z-30 mb-3 mr-12  ">
            <span className="sr-only">Open menu</span>

            <button
              id="menu-btn"
              className="cursor-pointer   "
              onClick={onMenuClickHandler}
            >
              <span className="hamtop"></span>
              <span className="hammiddle"></span>
              <span className="hambottom"></span>
            </button>
          </div>
        </div>

        <AnimatePresence>
          {showMenu && (
            <motion.div
              className="bg-[#1466A2] shadow-xl  z-20"
              initial={{ width: 0, height: "auto" }}
              animate={{
                width: "100%",
                height: "100vh",
                transition: { duration: 0.7 },
              }}
              exit={{
                width: 0,
                height: "screen",
                transition: { delay: 0.6, duration: 0.3 },
              }}
            >
              <motion.div
                className="grid grid-cols-1 text-sm"
                // className="flex flex-col p-4 gap-4"
                initial="closed"
                animate="open"
                exit="closed"
                variants={sideVariants}
              >
                {/* <NavLink to="">
                  <motion.p
                    variants={itemVariants}
                    className="font-semibold text-white pt-5 pl-5"
                    onClick={() => {
                      setOpen(false);
                      onNavClickHandler();
                    }}
                  >
                    <span className="border border-[#1466A2] py-1 px-6 bg-white text-[#1466A2] rounded-md shadow-lg text-xs">
                      Login
                    </span>
                  </motion.p>
                </NavLink> */}

                <NavLink to="/">
                  <motion.p
                    variants={itemVariants}
                    className="font-semibold text-white pt-5 pl-5"
                    onClick={() => {
                      setOpen(false);
                      onNavClickHandler();
                    }}
                  >
                    Home
                  </motion.p>
                </NavLink>

                <div className="mx-auto w-full max-w-md  bg-[#1466A2] ">
                  <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex gap-4 items-center font-semibold text-white pt-5 pl-5 z-30 ">
                          <span> About Us</span>
                          <BsChevronDown
                            size={14}
                            color="white"
                            className={`${
                              open ? "rotate-180 transform" : ""
                            }  text-purple-500`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-6 pb-2 text-sm text-gray-500">
                          <div className="relative grid gap-6 py-3 ">
                            <div
                              className="ml-2 text-white font-semibold"
                              onClick={() => {
                                setOpen(false);
                                onNavClickHandler();
                              }}
                            >
                              <NavLink to="/about-us-profile">
                                <p className="flex gap-2 items-center text-white ">
                                  Profile
                                </p>
                              </NavLink>
                              <NavLink to="/about-us-mission">
                                <p className="flex gap-2 pt-2 items-center ">
                                  Mission
                                </p>
                              </NavLink>
                              <NavLink to="/about-us-objectives">
                                <p className="flex gap-2 pt-2 items-center ">
                                  Objectives
                                </p>
                              </NavLink>
                              <NavLink to="/about-us-functions">
                                <p className="flex gap-2 pt-2 items-center ">
                                  Functions
                                </p>
                              </NavLink>
                              <NavLink to="/about-us-awards">
                                <p className="flex gap-2 pt-2 items-center ">
                                  Awards
                                </p>
                              </NavLink>
                              <NavLink to="/about-us-board-of-directors">
                                <p className="flex gap-2 pt-2 items-center ">
                                  Board of Directors
                                </p>
                              </NavLink>
                              <NavLink to="/about-us-smu-executives">
                                <p className="flex gap-2 pt-2 items-center ">
                                  SMU Executives
                                </p>
                              </NavLink>
                              <NavLink to="/about-us-organisation-chart">
                                <p className="flex gap-2 pt-2 items-center ">
                                  Organisation Chart
                                </p>
                              </NavLink>
                              <NavLink to="/about-us-milestones">
                                <p className="flex gap-2 pt-2 items-center ">
                                  Milestones
                                </p>
                              </NavLink>
                            </div>
                          </div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                  <Disclosure as="div" className="mt-2">
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex gap-4 items-center font-semibold text-white pt-5 pl-5 z-30 ">
                          <span>Facilities</span>
                          <BsChevronDown
                            size={14}
                            color="white"
                            className={`${
                              open ? "rotate-180 transform" : ""
                            }  text-purple-500`}
                          />
                        </Disclosure.Button>

                        <Disclosure.Panel className="px-6 pb-2 text-sm text-gray-500">
                          <div className="relative grid gap-6 py-3 ">
                            <div
                              className="ml-2 text-white font-semibold"
                              onClick={() => {
                                setOpen(false);
                                onNavClickHandler();
                              }}
                            >
                              <NavLink to="/facilities-head-office">
                                <p className="flex gap-2 items-center text-white ">
                                  Head Office
                                </p>
                              </NavLink>
                              <NavLink to="/facilities-regional-office">
                                <p className="flex gap-2 pt-2 items-center ">
                                  Regional Office
                                </p>
                              </NavLink>
                              <NavLink to="/facilities-plant">
                                <p className="flex gap-2 pt-2 items-center ">
                                  Plant
                                </p>
                              </NavLink>
                              <NavLink to="/facilities-dairy-and-co-operative-societies">
                                <p className="flex gap-2 pt-2 items-center ">
                                  Diary and Co-operative Society
                                </p>
                              </NavLink>
                            </div>
                          </div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                </div>

                {/* <NavLink to="">
                  <motion.p
                    variants={itemVariants}
                    className="font-semibold text-white pt-5 pl-5 z-30"
                    onClick={() => {
                      setOpen(false);
                      onNavClickHandler();
                    }}
                  >
                    Products
                  </motion.p>
                </NavLink> */}

                <NavLink to="/contact-us">
                  <motion.p
                    variants={itemVariants}
                    className="font-semibold text-white pt-5 pl-5 z-30"
                    onClick={() => {
                      setOpen(false);
                      onNavClickHandler();
                    }}
                  >
                    Contact Us
                  </motion.p>
                </NavLink>

                <NavLink to="/gallery">
                  <motion.p
                    variants={itemVariants}
                    className="font-semibold text-white pt-5 pl-5 z-30"
                    onClick={() => {
                      setOpen(false);
                      onNavClickHandler();
                    }}
                  >
                    Gallery
                  </motion.p>
                </NavLink>

                {/* <NavLink to="">
                  <motion.p
                    variants={itemVariants}
                    className="font-semibold text-white pt-5 pl-5 z-30"
                    onClick={() => {
                      setOpen(false);
                      onNavClickHandler();
                    }}
                  >
                    Agent Locator
                  </motion.p>
                </NavLink> */}
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>

        {/* -------------------------------------MOBILE NAVBAR END----------------------------------------------------------  */}

        {/* -------------------------------------DESKTOP NAVBAR START------------------------------------------------------- */}
        <>
          <div className="sticky-top bg-[#1466A2]  z-20 flex justify-center ">
            <nav className="hidden lg:flex lg:items-center  text-sm  2xl:text-base ">
              <NavLink to="/">
                <div
                  className="flex items-center justify-center text-white nav-link hover:text-green-100   p-4 active:bg-green-600 hover:bg-green-600 "
                  onMouseEnter={() => {
                    setAboutUsCaret(false);
                    setFacilitiesCaret(false);
                  }}
                >
                  <div className="flex items-center gap-2">
                    <p>
                      <AiFillHome size={20} color={"orange"} />
                    </p>
                    <p className=""> Home</p>
                  </div>
                </div>
              </NavLink>

              <div className="relative ">
                <button
                  type="button"
                  className="
              group text-white  inline-flex items-center   focus:outline-none   "
                  onMouseEnter={() => {
                    setAboutUsCaret(true);
                    setFacilitiesCaret(false);
                  }}
                >
                  <div className="nav-link cursor-pointer    flex items-center active:bg-green-600 hover:bg-green-600 p-4">
                    <div className="flex items-center gap-2">
                      <span>
                        <BsFillPersonFill size={20} color={"orange"} />
                      </span>
                      <span className={aboutUsCaret ? "" : ""}> About Us</span>
                      <NavbarCaret state={aboutUsCaret} />
                    </div>
                  </div>
                </button>
                <div
                  className={
                    aboutUsCaret
                      ? " opacity-100 -translate-y-3 transition ease-out duration-200 absolute z-10 mt-3 transform px-2 w-screen max-w-xs sm:px-0 "
                      : " hidden opacity-0 translate-y-1 absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2 "
                  }
                >
                  <div className="bg-slate-100">
                    <div className=" font-semibold text-gray-700 2xl:text-lg ">
                      <NavLink to="/about-us-profile">
                        <p className="flex gap-2 items-center hover:bg-[#1466A2]  hover:text-white px-1.5 py-2">
                          <p className="pl-4 ">Profile</p>
                        </p>
                      </NavLink>

                      <NavLink to="/about-us-mission">
                        <p className="flex gap-2 items-center hover:bg-[#1466A2]  hover:text-white px-1.5 py-2">
                          <p className="pl-4 ">Mission</p>
                        </p>
                      </NavLink>
                      <NavLink to="/about-us-objectives">
                        <p className="flex gap-2 items-center hover:bg-[#1466A2]  hover:text-white px-1.5 py-2">
                          <p className="pl-4 ">Objectives</p>
                        </p>
                      </NavLink>
                      <NavLink to="/about-us-functions">
                        <p className="flex gap-2 items-center hover:bg-[#1466A2]  hover:text-white px-1.5 py-2">
                          <p className="pl-4 ">Functions</p>
                        </p>
                      </NavLink>
                      <NavLink to="/about-us-awards">
                        <p className="flex gap-2 items-center hover:bg-[#1466A2]  hover:text-white px-1.5 py-2">
                          <p className="pl-4 ">Awards</p>
                        </p>
                      </NavLink>
                      <NavLink to="/about-us-board-of-directors">
                        <p className="flex gap-2 items-center hover:bg-[#1466A2]  hover:text-white px-1.5 py-2">
                          <p className="pl-4 ">Board of Directors</p>
                        </p>
                      </NavLink>
                      <NavLink to="/about-us-smu-executives">
                        <p className="flex gap-2 items-center hover:bg-[#1466A2]  hover:text-white px-1.5 py-2">
                          <p className="pl-4 ">SMU Executives</p>
                        </p>
                      </NavLink>
                      <NavLink to="/about-us-organisation-chart">
                        <p className="flex gap-2 items-center hover:bg-[#1466A2]  hover:text-white px-1.5 py-2">
                          <p className="pl-4 ">Organisation Chart</p>
                        </p>
                      </NavLink>
                      <NavLink to="/about-us-milestones">
                        <p className="flex gap-2 items-center hover:bg-[#1466A2]  hover:text-white px-1.5 py-2">
                          <p className="pl-4 ">Milestones</p>
                        </p>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>

              <div className="relative">
                <button
                  type="button"
                  className="group text-white  inline-flex items-center  focus:outline-none "
                  onMouseEnter={() => {
                    setAboutUsCaret(false);
                    setFacilitiesCaret(true);
                  }}
                >
                  <div className="nav-link   flex items-center active:bg-green-600 hover:bg-green-600 p-4 ">
                    <div className="flex items-center gap-2">
                      <span>
                        <BsFillBuildingFill size={20} color={"orange"} />
                      </span>
                      <span className={aboutUsCaret ? "" : ""}>Facilities</span>
                      <NavbarCaret state={facilitiesCaret} />
                    </div>
                  </div>
                </button>
                <div
                  className={
                    facilitiesCaret
                      ? " opacity-100 -translate-y-3 transition ease-out duration-200 absolute z-10 mt-3 transform px-2 w-screen max-w-xs sm:px-0  "
                      : " hidden opacity-0 translate-y-1 absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                  }
                >
                  <div className="  bg-slate-100  ">
                    <div className=" font-semibold  text-gray-700 2xl:text-lg  ">
                      <NavLink to="/facilities-head-office">
                        <p className="flex gap-2 items-center hover:bg-[#1466A2]  hover:text-white px-1.5 py-2">
                          <p className="pl-4 ">Head Office</p>
                        </p>
                      </NavLink>
                      <NavLink to="/facilities-regional-office">
                        <p className="flex gap-2 items-center pt-4  hover:bg-[#1466A2]  hover:text-white px-1.5 py-2  ">
                          {/* <BsBank2 size={16} /> */}
                          <p className="pl-4">Regional office</p>
                        </p>
                      </NavLink>
                      <NavLink to="/facilities-plant">
                        <p className="flex gap-2 items-center pt-4 hover:bg-[#1466A2]  hover:text-white px-1.5 py-2 ">
                          {/* <GiReceiveMoney size={16} /> */}
                          <p className="pl-4">Plant</p>
                        </p>
                      </NavLink>
                      <NavLink to="/facilities-dairy-and-co-operative-societies">
                        <p className="flex gap-2 items-center pt-4   hover:bg-[#1466A2]  hover:text-white px-1.5 py-2">
                          {/* <GiReceiveMoney size={16} /> */}
                          <p className="pl-4">Dairy and Co-operative Society</p>
                        </p>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>

              {/* <NavLink to="#">
                <div
                  className="flex items-center justify-center text-white nav-link hover:text-green-100   p-4 active:bg-green-600 hover:bg-green-600 "
                  onMouseEnter={() => {
                    setAboutUsCaret(false);
                    setFacilitiesCaret(false);
                  }}
                >
                  <div className="flex items-center gap-2">
                    <p>
                      <FaProductHunt size={20} color={"orange"} />
                    </p>
                    <p className=""> Product</p>
                  </div>
                </div>
              </NavLink> */}

              <NavLink to="/contact-us">
                <div
                  className="flex items-center justify-center text-white nav-link hover:text-green-100   p-4 active:bg-green-600 hover:bg-green-600 "
                  onMouseEnter={() => {
                    setAboutUsCaret(false);
                    setFacilitiesCaret(false);
                  }}
                >
                  <div className="flex items-center gap-2">
                    <p>
                      <BsFillTelephoneFill size={20} color={"orange"} />
                    </p>
                    <p className=""> Contact Us</p>
                  </div>
                </div>
              </NavLink>

              <NavLink to="/gallery">
                <div
                  className="flex items-center justify-center text-white nav-link hover:text-green-100   p-4 active:bg-green-600 hover:bg-green-600 "
                  onMouseEnter={() => {
                    setAboutUsCaret(false);
                    setFacilitiesCaret(false);
                  }}
                >
                  <div className="flex items-center gap-2">
                    <p>
                      <MdPhotoSizeSelectActual size={20} color={"orange"} />
                    </p>
                    <p className="">Gallery</p>
                  </div>
                </div>
              </NavLink>

              {/* <NavLink to="#">
                <div
                  className="flex items-center justify-center text-white nav-link hover:text-green-100   p-4 active:bg-green-600 hover:bg-green-600 "
                  onMouseEnter={() => {
                    setAboutUsCaret(false);
                    setFacilitiesCaret(false);
                  }}
                >
                  <div className="flex items-center gap-2">
                    <p>
                      <HiUserGroup size={20} color={"orange"} />
                    </p>
                    <p className="">Agent Locator</p>
                  </div>
                </div>
              </NavLink> */}

              {/* <NavLink to="">
                <div
                  className="flex items-center justify-center text-white nav-link hover:text-green-100 border-r border-white border-opacity-30  px-2"
                  onMouseEnter={() => {
                    setAboutUsCaret(false);
                    setFacilitiesCaret(false);
                  }}
                >
                  <p className="">Agent Locator</p>
                </div>
              </NavLink> */}
            </nav>
          </div>
        </>
        {/* DESKTOP NAVBAR END  */}
      </section>
    </div>
  );
}

const NavbarCaret = ({ state }) => {
  return (
    <svg
      className={
        state === true
          ? "transform rotate-180  h-5 w-5 text-green group-hover:text-green-200 transition ease-out duration-200"
          : "transform rotate-0 transition ease-out duration-200  h-5 w-5 text-green group-hover:text-green-200"
      }
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      aria-hidden="true"
    >
      <path
        fillRule="evenodd"
        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
        clipRule="evenodd"
      />
    </svg>
  );
};
