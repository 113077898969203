import React from "react";

const SMUExecutiveCard = ({ executiveObj }) => {
  return (
    <>
      <div className="shadow-lg">
        <div className="flex mb-2">
          <img
            src={executiveObj?.executive_image_url}
            className="h-64 w-full"
          />
        </div>

        <div className="pl-2 font-bold text-sm text-black pb-4">
          {executiveObj?.executive_name}
        </div>

        <div className="pl-2 font-bold text-xs text-black">
          {executiveObj?.executive_designation}
        </div>

        <div className="pl-2 text-xs text-black pb-3">
          {executiveObj?.executive_designation_description}
        </div>
      </div>
    </>
  );
};

export default SMUExecutiveCard;
