import React from "react";

const ExploreProductCard = ({ productObj }) => {
  return (
    <>
      <div className="shadow-lg transform transition duration-700 hover:scale-110">
        <div className="flex mb-2">
          <img src={productObj?.product_image_url} className="h-64 lg:w-full" />
        </div>
        <div className="pl-2 font-bold text-sm text-gray-700">
          {productObj?.product_name}
        </div>
        <div className="pl-2 font-semibold text-xs text-gray-600 pb-5">
          {productObj?.product_short_description}
        </div>
      </div>
    </>
  );
};

export default ExploreProductCard;
