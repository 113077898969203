import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import axios from "../../../api/axios";
import { SMU_EXECUTIVES_DETAILS_CONFIG_URL } from "../../../api/api_routing_urls";

import { HiOutlineRefresh } from "react-icons/hi";

import ImageDropzone from "../../../reusable-components/inputs/ImageDropzone/ImageDropzone";
import uploadFileController from "../../../utilities/uploadFiles/uploadFileController";
import deleteFile from "../../../utilities/uploadFiles/deleteFileController";

import Button from "../../../reusable-components/buttons/Button";
import SubmitButton from "../../../reusable-components/buttons/SubmitButton";
import Dropdown from "../../../reusable-components/inputs/Dropdowns/Dropdown";
import { NonEmptyString } from "../../../utilities/constants/regularexpressions";
import Input from "../../../reusable-components/inputs/InputTextBox/Input";
import { button, cancel_button } from "../../../theme/lightTheme";
import showToast from "../../../utilities/notification/NotificationModal";

const AddSMUExecutiveForm = ({
  getSmuExecutivesCompleteDetailsList,
  editSmuExecutivesDetails,
  setEditSmuExecutivesDetails,
  setShowAddForm,
  setIsAdd,
}) => {
  let unitDD = [
    { value: "Gangtok", label: "Gangtok" },
    { value: "Jorethang", label: "Jorethang" },
  ];
  const [selectedUnitName, setSelectedUnitName] = useState({});

  const [imageObj, setImageObj] = useState(null);
  const [changePhoto, setChangePhoto] = useState(false);

  const uploadFile = async (file) => {
    let fileUrl = "";

    try {
      await uploadFileController(file)
        .then((file_url) => {
          fileUrl = file_url;
        })
        .catch((err) => {
          fileUrl = "Error";
          console.error(err);
        });
      return fileUrl;
    } catch (error) {
      console.error(error);
      return "Error";
    }
  };

  const addSmuExecutiveImage = async () => {
    let file_url = "";

    if (imageObj !== null) {
      return await Promise.all((file_url = await uploadFile(imageObj[0]))).then(
        async () => {
          return await file_url;
        }
      );
    }
  };

  const submitSmuExecutiveImage = async () => {
    let file_Url = await addSmuExecutiveImage();

    return await file_Url;
  };

  // const isEdit = Object.keys(editSmuExecutivesDetails)?.length > 0;

  let isEdit =
    editSmuExecutivesDetails === undefined || editSmuExecutivesDetails === null
      ? false
      : Object?.keys(editSmuExecutivesDetails)?.length > 0
      ? true
      : false;

  const defaultValues = {
    executive_id: !isEdit ? "" : editSmuExecutivesDetails?.executive_id,
    executive_unit_name: !isEdit
      ? ""
      : {
          label: editSmuExecutivesDetails?.executive_unit_name,
          value: editSmuExecutivesDetails?.executive_unit_name,
        },
    executive_name: !isEdit ? "" : editSmuExecutivesDetails?.executive_name,
    executive_designation: !isEdit
      ? ""
      : editSmuExecutivesDetails?.executive_designation,
    executive_designation_description: !isEdit
      ? ""
      : editSmuExecutivesDetails?.executive_designation_description,
    executive_image_url: !isEdit
      ? ""
      : editSmuExecutivesDetails?.executive_image_url,
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    defaultValues: defaultValues,
  });

  const onSubmit = async (data) => {
    try {
      let imgUrl = undefined;
      if (!isEdit || changePhoto === true) {
        imgUrl = await submitSmuExecutiveImage();
      }

      let sendDataObj = {
        executive_unit_name: data?.executive_unit_name?.value,
        executive_name: data.executive_name,
        executive_designation: data.executive_designation,
        executive_designation_description:
          data.executive_designation_description,
        executive_image_url:
          imgUrl || editSmuExecutivesDetails?.executive_image_url,
      };

      let response = "";
      if (!isEdit) {
        sendDataObj.createdby = "admin";
        response = await axios.post(
          SMU_EXECUTIVES_DETAILS_CONFIG_URL,
          sendDataObj
        );
      } else {
        sendDataObj.updatedby = "admin";
        sendDataObj.executive_id = editSmuExecutivesDetails?.executive_id;
        response = await axios.post(
          `${SMU_EXECUTIVES_DETAILS_CONFIG_URL}/update`,
          sendDataObj
        );
      }

      if (response.status === 200) {
        if (
          isEdit &&
          imgUrl &&
          changePhoto === true &&
          defaultValues?.executive_image_url
        ) {
          await deleteFile(defaultValues?.executive_image_url);
          console.log(
            "Selected SMU Executive Image details has been deleted successfully from Spaces.",
            "success"
          );
        } else {
          console.log(
            "Selected SMU Executive Image details deletion from Spaces failed.",
            "error"
          );
        }

        if (isEdit) {
          setEditSmuExecutivesDetails({});
          console.log(
            "Selected SMU Executive details have been updated successfully.",
            "success"
          );
        } else {
          console.log(
            "SMU Executive details have been added successfully.",
            "success"
          );
        }
        getSmuExecutivesCompleteDetailsList();
      } else {
        if (isEdit) {
          console.log("Save SMU Executive Details Error:", response.data);
        } else {
          if (
            response.data.saveSMUExecutivesDetails.includes("unique_executive")
          ) {
            console.log(
              "Data already exists. Cannot insert duplicate data.",
              "error"
            );
          } else {
            console.log(
              "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
              "error"
            );
          }
        }
        return;
      }
      setShowAddForm(false);
      reset();
    } catch (error) {
      console.error("error", error);
      if (!error?.response) {
        console.log("No Server Response", "error");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.", "error");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  useEffect(() => {
    if (editSmuExecutivesDetails?.executive_unit_name) {
      setSelectedUnitName({
        label: editSmuExecutivesDetails?.executive_unit_name,
        value: editSmuExecutivesDetails?.executive_unit_name,
      });
    }
  }, [editSmuExecutivesDetails]);

  return (
    <section>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Dropdown
          defaultName="executive_unit_name"
          register={register}
          labelname="Executive's Unit Name"
          required={true}
          pattern={NonEmptyString}
          errors={errors}
          classes={`text-sm w-full z-60`}
          setError={setError}
          clearError={clearErrors}
          onChangeInput={null}
          control={control}
          data={unitDD}
          // defaultValue={defaultValues?.executive_unit_name}
          defaultValue={selectedUnitName}
          setValue={setValue}
          setSelected={setSelectedUnitName}
          selected={selectedUnitName}
          maxMenuHeight={120}
        />

        <Input
          defaultName="executive_name"
          register={register}
          name="Executive's Name"
          required={true}
          pattern={null}
          errors={errors}
          placeholder="Enter executive's name"
          setError={setError}
          clearError={clearErrors}
          autoComplete="off"
          type="text"
          classes={`px-3 py-2 text-sm w-full mb-3`}
          onChangeInput={null}
          defaultValue={defaultValues.executive_name}
          setValue={setValue}
        />

        <Input
          defaultName="executive_designation"
          register={register}
          name="Executive's Designation"
          required={true}
          pattern={null}
          errors={errors}
          placeholder="Enter executive's designation"
          setError={setError}
          clearError={clearErrors}
          autoComplete="off"
          type="text"
          classes={`px-3 py-2 text-sm w-full mb-3`}
          onChangeInput={null}
          defaultValue={defaultValues.executive_designation}
          setValue={setValue}
        />

        <Input
          defaultName="executive_designation_description"
          register={register}
          name="Executives's Designation Description"
          required={true}
          pattern={null}
          errors={errors}
          placeholder="Enter a short description related to the designation"
          setError={setError}
          clearError={clearErrors}
          autoComplete="off"
          type="text"
          classes={`px-3 py-2 text-sm w-full mb-3`}
          onChangeInput={null}
          defaultValue={defaultValues.executive_designation_description}
          setValue={setValue}
        />

        <div className="flex justify-between  mt-5">
          <div className="flex items-center font-medium text-left text-gray-900 pl-1">
            Executive's Photo <span className="text-red-700">*</span>
          </div>

          <div>
            {isEdit && (
              <div
                onClick={() => {
                  setChangePhoto((prev) => !prev);
                }}
                className="flex items-center gap-x-2 bg-primary py-1 px-3 rounded-md cursor-pointer"
              >
                <div className="text-white">Change Photo</div>

                <div className="text-primary bg-white rounded-full h-4 w-4 flex justify-center items-center">
                  <HiOutlineRefresh size={12} />
                </div>
              </div>
            )}
          </div>
        </div>

        {changePhoto === true ? (
          <>
            <div>
              <p className="text-xs text-gray-700 font-semibold mt-3 mb-1">
                Previous Image
              </p>
              <img
                className="h-48 object-cover"
                src={defaultValues?.executive_image_url}
                alt="bod"
              ></img>
            </div>
            <ImageDropzone
              fieldTitle=""
              onChange={setImageObj}
              multiple={false}
              available_image={""}
            />
          </>
        ) : (
          <div>
            {isEdit ? (
              <div className="mt-3 border border-primary flex justify-center">
                <img
                  className="h-64 object-cover my-16"
                  src={defaultValues?.executive_image_url}
                  alt="executive"
                />
              </div>
            ) : (
              <>
                <ImageDropzone
                  fieldTitle=""
                  onChange={setImageObj}
                  multiple={false}
                  available_image={""}
                />
              </>
            )}
          </div>
        )}

        <div className="flex justify-between mt-10">
          <SubmitButton
            isSubmitting={isSubmitting}
            label={!isEdit ? "Submit" : "Update"}
            className={button}
          />
          <Button
            onClick={() => {
              setEditSmuExecutivesDetails({});
              setValue("", "");
              setShowAddForm(false);
              setIsAdd(true);
            }}
            aria-label="Cancel SMU Executive Details Update Button"
            className={cancel_button}
          >
            Cancel
          </Button>
        </div>
      </form>
    </section>
  );
};

export default AddSMUExecutiveForm;
