import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";

import { MdAddCircleOutline } from "react-icons/md";
import { RxUpdate } from "react-icons/rx";

import axios from "../../../api/axios";
import { MILESTONE_DETAILS_CONFIG_URL } from "../../../api/api_routing_urls";

import showToast from "../../../utilities/notification/NotificationModal";

import AddMilestoneForm from "./addMilestoneForm.component";
import MilestoneList from "./milestoneList.component";
import Dashboard from "../../dashboard-components/dashboard.component";

const MilestoneConfig = () => {
  const [milestoneCompleteDetailsList, setMilestoneCompleteDetailsList] =
    useState([]);

  const [showAddForm, setShowAddForm] = useState(false);
  const [isAdd, setIsAdd] = useState(true);
  const [editMilestoneDetails, setEditMilestoneDetails] = useState({});

  const isEdit = Object.keys(editMilestoneDetails).length > 0 ? true : false;

  const getMilestoneCompleteDetailsList = async () => {
    try {
      const response = await axios.get(MILESTONE_DETAILS_CONFIG_URL);
      // console.log("Milestone with complete details List", { response });
      response.status === 200 &&
        setMilestoneCompleteDetailsList(response.data.milestoneCompleteDetails);
      response.status === 202 &&
        console.log("No milestones found in the system.", "error");
    } catch (error) {
      console.error("getMilestoneCompleteDetailsList", error);
      if (!error?.response) {
        console.log("No Server Response");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.", "error");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getMilestoneCompleteDetailsList();
      setEditMilestoneDetails({});
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      <Dashboard sidebarType={"Super Admin"}>
        <ToastContainer />
        {showAddForm ? (
          <div className="flex flex-col mb-10">
            <div className="flex gap-2 items-center pb-5">
              <div className="text-primary font-semibold text-lg border-b border-primary">
                {isAdd ? <>Add Milestone</> : <>Update Milestone Details</>}
              </div>
            </div>

            <AddMilestoneForm
              getMilestoneCompleteDetailsList={getMilestoneCompleteDetailsList}
              editMilestoneDetails={editMilestoneDetails}
              setEditMilestoneDetails={setEditMilestoneDetails}
              setShowAddForm={setShowAddForm}
              setIsAdd={setIsAdd}
            />
          </div>
        ) : (
          <div className="grid grid-cols-1">
            <MilestoneList
              milestoneCompleteDetailsList={milestoneCompleteDetailsList}
              getMilestoneCompleteDetailsList={getMilestoneCompleteDetailsList}
              setEditMilestoneDetails={setEditMilestoneDetails}
              setShowAddForm={setShowAddForm}
              setIsAdd={setIsAdd}
            />
          </div>
        )}
      </Dashboard>
    </>
  );
};

export default MilestoneConfig;
