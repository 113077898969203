import React from "react";

import Dashboard from "../dashboard-components/dashboard.component";

export default function SystemAdminDashboard() {
  // const { auth } = useAuth();
  return (
    <Dashboard sidebarType={"Super Admin"}>
      {/* <DashboardConfig /> */}
      <div className="bg-white min-h-screen">WELCOME ADMIN!</div>
    </Dashboard>
  );
}
