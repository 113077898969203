import React, { useState, useEffect } from "react";

import axios from "../../../api/axios";
import { EVENT_DETAILS_CONFIG_URL } from "../../../api/api_routing_urls";

import ViewEventDetails from "./view_event_details.component";

const Events = () => {
  const [eventCompleteDetailsList, setEventCompleteDetailsList] = useState([]);
  const [showEventDetails, setShowEventDetails] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const getEventCompleteDetailsList = async () => {
    try {
      const response = await axios.get(EVENT_DETAILS_CONFIG_URL);
      // console.log("Event with complete details List", { response });
      response.status === 200 &&
        setEventCompleteDetailsList(response.data.eventCompleteDetails);
      response.status === 202 &&
        console.log("No events found in the system.", "error");
    } catch (error) {
      console.error("getEventCompleteDetailsList", error);
      if (!error?.response) {
        console.log("No Server Response");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.", "error");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setShowEventDetails(false);
      setSelectedEvent(null);
      getEventCompleteDetailsList();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const onEventClickHandler = (eventObj) => {
    setSelectedEvent(eventObj);
    setShowEventDetails(true);
  };

  return (
    <section className="m-8 md:m-20">
      <>
        {showEventDetails ? (
          <>
            <ViewEventDetails
              selectedEvent={selectedEvent}
              setSelectedEvent={setSelectedEvent}
              setShowEventDetails={setShowEventDetails}
            />
          </>
        ) : (
          <>
            <div className="mb-16 md:mb-28 flex justify-center text-xl lg:text-2xl font-semibold text-primary underline underline-offset-4 uppercase">
              Events
            </div>

            <div>
              {eventCompleteDetailsList?.length > 0 ? (
                <>
                  <div className="mt-4 pb-4 grid grid-cols-1 md:grid-cols-2 md:gap-x-10 gap-y-20">
                    {eventCompleteDetailsList?.map((eventObj, index) => (
                      <div
                        key={index}
                        onClick={() => {
                          onEventClickHandler(eventObj);
                        }}
                        className="cursor-pointer"
                      >
                        <div>
                          <img
                            src={eventObj?.images_list[0]?.file_url}
                            className="h-[50vh] w-full object-cover"
                          />
                        </div>

                        <div className="font-semibold text-center mt-4">
                          {eventObj?.event_name}
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <div className="flex justify-center text-sm text-primary py-2">
                  No events configured in the system.
                </div>
              )}
            </div>
          </>
        )}
      </>
    </section>
  );
};

export default Events;
