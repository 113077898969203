import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";

import { MdAddCircleOutline } from "react-icons/md";
import { RxUpdate } from "react-icons/rx";

import axios from "../../../api/axios";
import { PRODUCT_DETAILS_CONFIG_URL } from "../../../api/api_routing_urls";

import showToast from "../../../utilities/notification/NotificationModal";

import AddProductForm from "./addProductForm.component";
import ProductList from "./productList.component";
import Dashboard from "../../dashboard-components/dashboard.component";

const ProductConfig = () => {
  const [productCompleteDetailsList, setProductCompleteDetailsList] = useState(
    []
  );

  const [showAddForm, setShowAddForm] = useState(false);
  const [isAdd, setIsAdd] = useState(true);
  const [editProductDetails, setEditProductDetails] = useState({});

  const isEdit = Object.keys(editProductDetails).length > 0 ? true : false;

  const getProductCompleteDetailsList = async () => {
    try {
      const response = await axios.get(PRODUCT_DETAILS_CONFIG_URL);
      // console.log("Product with complete details List", { response });
      response.status === 200 &&
        setProductCompleteDetailsList(response.data.productCompleteDetails);
      response.status === 202 &&
        console.log("No products found in the system.", "error");
    } catch (error) {
      console.error("getProductCompleteDetailsList", error);
      if (!error?.response) {
        console.log("No Server Response");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.", "error");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getProductCompleteDetailsList();
      setEditProductDetails({});
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      <Dashboard sidebarType={"Super Admin"}>
        <ToastContainer />
        {showAddForm ? (
          <div className="flex flex-col mb-10">
            <div className="flex gap-2 items-center pb-5">
              {/* <div className="">
                {isAdd ? (
                  <MdAddCircleOutline size={18} color="green" />
                ) : (
                  <RxUpdate size={17} color="blue" />
                )}
              </div> */}
              <div className="text-primary font-semibold text-lg border-b border-primary">
                {isAdd ? <>Add Product</> : <>Update Product Details</>}
              </div>
            </div>

            <AddProductForm
              getProductCompleteDetailsList={getProductCompleteDetailsList}
              editProductDetails={editProductDetails}
              setEditProductDetails={setEditProductDetails}
              setShowAddForm={setShowAddForm}
              setIsAdd={setIsAdd}
            />
          </div>
        ) : (
          <div className="grid grid-cols-1">
            <ProductList
              productCompleteDetailsList={productCompleteDetailsList}
              getProductCompleteDetailsList={getProductCompleteDetailsList}
              setEditProductDetails={setEditProductDetails}
              setShowAddForm={setShowAddForm}
              setIsAdd={setIsAdd}
            />
          </div>
        )}
      </Dashboard>
    </>
  );
};

export default ProductConfig;
