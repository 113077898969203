import React from "react";
import { Link, NavLink } from "react-router-dom";
// import UserMenu from "./header/userMenu.component";

import Logo from "../../assets/sikkimmilklogo.png";

function Header({ sidebarOpen, setSidebarOpen }) {
  return (
    <header className="sticky top-0 bg-white border-b z-20">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between p-3">
          {/* Header: Left side */}

          <NavLink end to="/" className="block">
            <div className="flex items-center">
              <img src={Logo} width="40" height="40" />
              {
                <p className="text-slate-800 text-sm font-medium pl-2">
                  The Sikkim Co-operative Milk <br />
                  Producers' Union Ltd
                </p>
              }
            </div>
          </NavLink>

          <div className="flex">
            {/* Hamburger button */}
            <button
              className="text-slate-500 hover:text-slate-600 lg:hidden"
              aria-controls="sidebar"
              aria-expanded={sidebarOpen}
              onClick={() => setSidebarOpen(!sidebarOpen)}
            >
              <span className="sr-only">Open sidebar</span>
              <svg
                className="w-6 h-6 fill-current"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="4" y="5" width="16" height="2" />
                <rect x="4" y="11" width="16" height="2" />
                <rect x="4" y="17" width="16" height="2" />
              </svg>
            </button>
          </div>
          {/* Header: Right side */}

          <Link
            to="/login 
          "
          >
            <p
              onClick={() => localStorage.removeItem("Content Admin")}
              className="border border-[#1466A2] py-2 px-6 bg-white text-[#1466A2] rounded-sm shadow-lg text-sm hover:bg-[#1466A2] hover:text-white "
            >
              Logout
            </p>
          </Link>
        </div>
      </div>
    </header>
  );
}

export default Header;
