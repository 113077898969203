import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import axios from "../../../api/axios";
import { PRODUCT_DETAILS_CONFIG_URL } from "../../../api/api_routing_urls";

import { HiOutlineRefresh } from "react-icons/hi";

import ImageDropzone from "../../../reusable-components/inputs/ImageDropzone/ImageDropzone";
import uploadFileController from "../../../utilities/uploadFiles/uploadFileController";
import deleteFile from "../../../utilities/uploadFiles/deleteFileController";

import Button from "../../../reusable-components/buttons/Button";
import SubmitButton from "../../../reusable-components/buttons/SubmitButton";
import Input from "../../../reusable-components/inputs/InputTextBox/Input";
import { button, cancel_button } from "../../../theme/lightTheme";
import showToast from "../../../utilities/notification/NotificationModal";

const AddProductForm = ({
  getProductCompleteDetailsList,
  editProductDetails,
  setEditProductDetails,
  setShowAddForm,
  setIsAdd,
}) => {
  const [imageObj, setImageObj] = useState(null);
  const [changePhoto, setChangePhoto] = useState(false);

  const uploadFile = async (file) => {
    let fileUrl = "";

    try {
      await uploadFileController(file)
        .then((file_url) => {
          fileUrl = file_url;
        })
        .catch((err) => {
          fileUrl = "Error";
          console.error(err);
        });
      return fileUrl;
    } catch (error) {
      console.error(error);
      return "Error";
    }
  };

  const addProductImage = async () => {
    let file_url = "";

    if (imageObj !== null) {
      return await Promise.all((file_url = await uploadFile(imageObj[0]))).then(
        async () => {
          return await file_url;
        }
      );
    }
  };

  const submitProductImage = async () => {
    // let prd_image_url = "";

    let file_Url = await addProductImage();

    // prd_image_url = JSON.stringify(file_Url);

    return await file_Url;
  };

  const isEdit = Object.keys(editProductDetails)?.length > 0;

  const defaultValues = {
    product_id: !isEdit ? "" : editProductDetails?.product_id,
    product_name: !isEdit ? "" : editProductDetails?.product_name,
    product_short_description: !isEdit
      ? ""
      : editProductDetails?.product_short_description,
    product_rate: !isEdit ? "" : editProductDetails?.product_rate,
    product_image_url: !isEdit ? "" : editProductDetails?.product_image_url,
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    defaultValues: defaultValues,
  });

  const onSubmit = async (data) => {
    try {
      let imgUrl = undefined;
      if (!isEdit || changePhoto === true) {
        imgUrl = await submitProductImage();
      }

      let sendDataObj = {
        product_name: data.product_name,
        product_short_description: data.product_short_description,
        product_rate: data.product_rate,
        product_image_url: imgUrl || editProductDetails?.product_image_url,
      };

      let response = "";
      if (!isEdit) {
        sendDataObj.createdby = "admin";
        response = await axios.post(PRODUCT_DETAILS_CONFIG_URL, sendDataObj);
      } else {
        sendDataObj.updatedby = "admin";
        sendDataObj.product_id = editProductDetails?.product_id;

        response = await axios.post(
          `${PRODUCT_DETAILS_CONFIG_URL}/update`,
          sendDataObj
        );
      }

      if (response.status === 200) {
        if (
          isEdit &&
          imgUrl &&
          changePhoto === true &&
          defaultValues?.product_image_url
        ) {
          await deleteFile(defaultValues?.product_image_url);
          console.log(
            "Product Image details has been deleted successfully from Spaces.",
            "success"
          );
        } else {
          console.log(
            "Product Image details deletion from Spaces failed.",
            "error"
          );
        }

        if (isEdit) {
          setEditProductDetails({});
          console.log(
            "Product details have been updated successfully.",
            "success"
          );
        } else {
          console.log(
            "Product details have been added successfully.",
            "success"
          );
        }
        getProductCompleteDetailsList();
      } else {
        if (isEdit) {
          console.log("Save Product Details Error:", response.data);
        } else {
          if (response.data.saveProductDetails.includes("unique_product")) {
            console.log(
              "Data already exists. Cannot insert duplicate data.",
              "error"
            );
          } else {
            console.log(
              "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
              "error"
            );
          }
        }
        return;
      }
      setShowAddForm(false);
      reset();
    } catch (error) {
      console.error("error", error);
      if (!error?.response) {
        console.log("No Server Response", "error");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.", "error");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  return (
    <section>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          defaultName="product_name"
          register={register}
          name="Product Name"
          required={true}
          pattern={null}
          errors={errors}
          placeholder="Enter product name"
          setError={setError}
          clearError={clearErrors}
          autoComplete="off"
          type="text"
          classes={`px-3 py-2 text-sm w-full mb-3`}
          onChangeInput={null}
          defaultValue={defaultValues.product_name}
          setValue={setValue}
        />

        <Input
          defaultName="product_short_description"
          register={register}
          name="Short Description"
          required={true}
          pattern={null}
          errors={errors}
          placeholder="Enter a short description for the product"
          setError={setError}
          clearError={clearErrors}
          autoComplete="off"
          type="text"
          classes={`px-3 py-2 text-sm w-full mb-3`}
          onChangeInput={null}
          defaultValue={defaultValues.product_short_description}
          setValue={setValue}
        />

        <Input
          defaultName="product_rate"
          register={register}
          name="Product Rate"
          required={true}
          pattern={null}
          errors={errors}
          placeholder="Enter the rate of the product"
          setError={setError}
          clearError={clearErrors}
          autoComplete="off"
          type="number"
          classes={`px-3 py-2 text-sm w-full mb-3`}
          onChangeInput={null}
          defaultValue={defaultValues.product_rate}
          setValue={setValue}
        />

        <div className="flex justify-between  mt-5">
          <div className="flex items-center font-medium text-left text-gray-900 pl-1">
            Product Photo <span className="text-red-700">*</span>
          </div>

          <div>
            {isEdit && (
              <div
                onClick={() => {
                  setChangePhoto((prev) => !prev);
                }}
                className="flex items-center gap-x-2 bg-primary py-1 px-3 rounded-md cursor-pointer"
              >
                <div className="text-white">Change Photo</div>

                <div className="text-primary bg-white rounded-full h-4 w-4 flex justify-center items-center">
                  <HiOutlineRefresh size={12} />
                </div>
              </div>
            )}
          </div>
        </div>

        {changePhoto === true ? (
          <>
            <div>
              <p className="text-xs text-gray-700 font-semibold mt-3 mb-1">
                Previous Image
              </p>
              <img
                className="h-48 object-cover"
                src={defaultValues?.product_image_url}
                alt="product"
              ></img>
            </div>
            <ImageDropzone
              fieldTitle=""
              onChange={setImageObj}
              multiple={false}
              available_image={""}
            />
          </>
        ) : (
          <div>
            {isEdit ? (
              <div className="mt-3 border border-primary flex justify-center">
                <img
                  className="h-64 object-cover my-16"
                  src={defaultValues?.product_image_url}
                  alt="product"
                />
              </div>
            ) : (
              <>
                <ImageDropzone
                  fieldTitle=""
                  onChange={setImageObj}
                  multiple={false}
                  available_image={""}
                />
              </>
            )}
          </div>
        )}

        <div className="flex justify-between mt-10">
          <SubmitButton
            isSubmitting={isSubmitting}
            label={!isEdit ? "Submit" : "Update"}
            className={button}
          />
          <Button
            onClick={() => {
              setEditProductDetails({});
              setValue("", "");
              setShowAddForm(false);
              setIsAdd(true);
            }}
            aria-label="Cancel Product Details Update Button"
            className={cancel_button}
          >
            Cancel
          </Button>
        </div>
      </form>
    </section>
  );
};

export default AddProductForm;
