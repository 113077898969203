import React, { useEffect, useState } from "react";
import { IoReloadCircle } from "react-icons/io5";
import { CAPTCHA_URL } from "../../api/api_routing_urls";
import axios from "../../api/axios";
import SpinnerWithText from "../../reusable-components/loader/SpinnerWithText";
import Error from "../../reusable-components/outputs/Error";

const Captcha = ({
  setCaptchaSuccess,
  captchaReset,
  setCaptchaInput,
  setSessionId,
}) => {
  const [captchaError, setCaptchaError] = useState(false);
  const [captchaErrorMessage, setCaptchaErrorMessage] = useState("");
  const [captcha, setCaptcha] = useState([]);
  const [isCaptcahLoading, setIsCapchaLoading] = useState(true);
  const [code, setCode] = useState();
  const [resetDisabled, setResetDisabled] = useState(false);

  const createCaptcha = async () => {
    //clear the contents of captcha div first
    document.getElementById("captcha").innerHTML = "";

    //clear the contents of captcha div first
    if (document?.getElementById("captcha")?.innerHTML) {
      document.getElementById("captcha").innerHTML = "";
    }

    let canv = document.createElement("canvas");
    canv.id = "captcha";
    canv.width = 200;
    canv.height = 40;
    let ctx = canv.getContext("2d");
    ctx.font = "20px Deportees";
    ctx.strokeText(captcha.join(""), 0, 30);
    //storing captcha so that can validate you can save it somewhere else according to your specific requirements
    setCode(captcha.join(""));
    document.getElementById("captcha").appendChild(canv);
    // adds the canvas to the body element
  };

  const validateCaptcha = (e) => {
    if (document.getElementById("cpatchaTextBox").value === code) {
      setCaptchaInput(e.target.value);
      setCaptchaSuccess(true);
    } else {
      setCaptchaSuccess(false);
    }
  };

  const getCaptchaFromServer = async () => {
    try {
      setResetDisabled(true);
      setIsCapchaLoading(true);

      const response = await axios.get(CAPTCHA_URL);

      if (response?.data?.status === 201) {
        setCaptcha(response?.data?.captcha);
        console.log(response?.data?.captcha);
        setSessionId(response?.data?.session_id);
        console.log(response?.data?.session_id);
        setIsCapchaLoading(false);
      } else {
        setCaptcha([]);
        setSessionId("");
        setIsCapchaLoading(true);
      }
    } catch (err) {
      console.error(err);

      setCaptchaSuccess(false);
      if (err.message.includes("429")) {
        setCaptchaError(true);
        setCaptchaErrorMessage("Too Many Requests, please try again later.");
      } else {
        setCaptchaError(true);
        setCaptchaErrorMessage("Some error occured, please try again later.");
      }
    } finally {
      console.log("getCaptchaFromServer");
      setIsCapchaLoading(false);
      setTimeout(() => {
        setResetDisabled(false); // Enable the reset button after a delay
      }, 3000); // 3000 milliseconds delay (3 seconds)
    }
  };

  useEffect(() => {
    console.log({ captcha });
    if (captcha?.length > 0 && !isCaptcahLoading) {
      createCaptcha();
    }
  }, [isCaptcahLoading, captcha]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      getCaptchaFromServer();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    setCaptchaSuccess(false);
  }, [captchaReset]);

  return (
    // <>
    //   {isCaptcahLoading ? (
    //     <section className=" p-3 mt-3 bg-gray-50">
    //       <SpinnerWithText />
    //     </section>
    //   ) : captchaError ? (
    //     <section className="my-3">
    //       <Error classes={"xl:text-lg "} message={captchaErrorMessage} />
    //     </section>
    //   ) : (
    //     <section className=" p-3 bg-gray-50">
    //       <div id="captcha" className="h-[40px]" />
    //       <span className="captchaimg h-20 w-full"></span>
    //       <input
    //         id="cpatchaTextBox"
    //         type="text"
    //         placeholder="Captcha"
    //         className="rounded text-sm border border-gray-400 w-48 p-1"
    //         onChange={(e) => validateCaptcha(e)}
    //         autoComplete="off"
    //       />
    //       {/* un comment for reset */}
    //       <button
    //         id="captchaReset"
    //         type="reset"
    //         onClick={() => getCaptchaFromServer()}
    //         className="text-gray-500 ml-2"
    //       >
    //         <IoReloadCircle size={24} />
    //       </button>
    //     </section>
    //   )}
    // </>

    <>
      {isCaptcahLoading ? (
        <section className=" p-3 mt-3 bg-gray-50">
          {/* <SpinnerWithText /> */}
          loading...
        </section>
      ) : captchaError ? (
        <section className="my-3">
          <Error classes={"xl:text-lg "} message={captchaErrorMessage} />
        </section>
      ) : (
        <section className=" p-3 bg-gray-50">
          <div id="captcha" className="h-[40px]" />
          <span className="captchaimg h-20 w-full"></span>
          <input
            id="cpatchaTextBox"
            type="text"
            placeholder="Captcha"
            className="rounded text-sm border border-gray-400 w-48 p-1"
            onChange={(e) => validateCaptcha(e)}
            autoComplete="off"
          />
          {/* un comment for reset */}
          <button
            id="captchaReset"
            type="reset"
            onClick={() => {
              getCaptchaFromServer();
            }}
            className={
              resetDisabled ? "text-slate-200 ml-2" : "text-slate-500 ml-2"
            }
            disabled={resetDisabled} // Disable button based on state
          >
            <IoReloadCircle size={24} />
          </button>
        </section>
      )}
    </>
  );
};
export default Captcha;
