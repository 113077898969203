import React, { useEffect } from "react";
import Dashboard from "../../dashboard-components/dashboard.component";
import H2withIcon from "../../../reusable-components/headings/H2withIcon";
import { MdAssignmentTurnedIn } from "react-icons/md";
import AddButton from "../../../reusable-components/buttons/AddButton";
import { useState } from "react";
import AddNoticeForm from "./AddNoticeForm";
import NoticeList from "./NoticeList";
import axios from "../../../api/axios";
import { NOTICE_DETAILS_URL } from "../../../api/api_routing_urls";
import showToast from "../../../utilities/notification/NotificationModal";
import DeleteModal from "../../../reusable-components/modals/DeleteModal";

export default function NoticeConfig() {
  const [showAddForm, setShowAddForm] = useState(false);
  const [noticeList, setNoticeList] = useState([]);
  const [editNoticeDetails, setEditNoticeDetails] = useState();
  const [showDelete, setShowDelete] = useState(false);
  const [noticeId, setNoticeId] = useState();

  const getNoticeDetails = async () => {
    try {
      const response = await axios.get(NOTICE_DETAILS_URL);
      response.status === 200 &&
        setNoticeList(response?.data?.noticeList[0]?.get_notice_details);
      response.status === 202 &&
        console.log("No awards found in the system.", "error");
    } catch (error) {
      console.error("getAwardCompleteDetailsList", error);
      if (!error?.response) {
        console.log("No Server Response");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.", "error");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  const deleteNotice = async () => {
    try {
      const data = {};
      data.notice_id = noticeId;
      const response = await axios.post(`${NOTICE_DETAILS_URL}/delete`, data);

      console.log("response", response);

      response.status === 200 && showToast("Notice deleted", "success");
      response.status === 202 &&
        showToast(
          "Processing request, if the ntoice is not deleted please try again later.",
          "warning"
        );
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact adminstrator",
          "error"
        );
      }
    } finally {
      getNoticeDetails();
      setShowDelete(false);
    }
  };

  let isEdit =
    editNoticeDetails === undefined || editNoticeDetails === null
      ? false
      : Object?.keys(editNoticeDetails)?.length > 0
      ? true
      : false;

  useEffect(() => {
    getNoticeDetails();
  }, []);

  return (
    <div>
      <Dashboard sidebarType={"Super Admin"}>
        {showAddForm ? (
          <AddNoticeForm
            setShowAddForm={setShowAddForm}
            editNoticeDetails={editNoticeDetails}
            setEditNoticeDetails={setEditNoticeDetails}
            getNoticeDetails={getNoticeDetails}
          />
        ) : (
          <>
            <div className="flex justify-between items-center pl-4">
              <H2withIcon>
                <MdAssignmentTurnedIn color="#1466A2" size={16} />
                <span className="pl-3">Notice configuration</span>
              </H2withIcon>

              <div className="flex gap-4 justify-end">
                <AddButton
                  label="Add Notice"
                  onclick={() => {
                    setShowAddForm(true);
                  }}
                />
              </div>
            </div>

            <NoticeList
              setShowAddForm={setShowAddForm}
              noticeList={noticeList}
              setEditNoticeDetails={setEditNoticeDetails}
              getNoticeDetails={getNoticeDetails}
              setNoticeId={setNoticeId}
              setShowDelete={setShowDelete}
            />
          </>
        )}

        <DeleteModal
          open={showDelete}
          setOpen={setShowDelete}
          message={"The notice would be deleted permenantly. Are you sure?"}
          onDelete={deleteNotice}
          setShowDelete={setShowDelete}
        />
      </Dashboard>
    </div>
  );
}
