const LOGIN_URL = "/login";
const LOGOUT_URL = "/logout";
const AUDIT_TRAIL_CONFIG_URL = "/sysAdmin/auditTrailConfig";
const CHANGE_PASSWORD = "/sysAdmin/changePassword";
const GET_DASHBOARD_REPORT_URL = "/sysAdmin/getDashboardReport";
const SEND_MAIL_URL = "/sendmail";

//#region Public URLs
const AWS_ACCESS_URL = "/getAWSAccess";
const RAS_KEY_URL = "/getRsaPublicKey";
const CAPTCHA_URL = "/getCaptcha";
const ROLELIST_URL = "/rolelist";

//#endregion

const PRODUCT_DETAILS_CONFIG_URL = "/sysAdmin/productsConfig";
const AWARD_DETAILS_CONFIG_URL = "/sysAdmin/awardsConfig";
const BOARD_OF_DIRECTORS_DETAILS_CONFIG_URL = "/sysAdmin/bodConfig";
const SMU_EXECUTIVES_DETAILS_CONFIG_URL = "/sysAdmin/smuExecutivesConfig";
const MILESTONE_DETAILS_CONFIG_URL = "/sysAdmin/milestonesConfig";
const EVENT_DETAILS_CONFIG_URL = "/sysAdmin/eventsConfig";
const YOUTUBE_GALLERY_DETAILS_CONFIG_URL = "/sysAdmin/youtubeGalleryConfig";

const GALLERY_DETAILS_CONFIG_URL = "/sysAdmin/galleryConfig";
const NOTICE_DETAILS_URL = "/sysAdmin/noticeDetailsConfig";
const LOGIN_USING_COOKIE_URL = "sysAdmin/loginUsingCookie";

module.exports = {
  LOGIN_URL,
  LOGOUT_URL,
  AUDIT_TRAIL_CONFIG_URL,
  CHANGE_PASSWORD,
  GET_DASHBOARD_REPORT_URL,
  SEND_MAIL_URL,
  AWS_ACCESS_URL,
  RAS_KEY_URL,
  CAPTCHA_URL,
  ROLELIST_URL,
  NOTICE_DETAILS_URL,

  PRODUCT_DETAILS_CONFIG_URL,
  AWARD_DETAILS_CONFIG_URL,
  BOARD_OF_DIRECTORS_DETAILS_CONFIG_URL,
  SMU_EXECUTIVES_DETAILS_CONFIG_URL,
  MILESTONE_DETAILS_CONFIG_URL,
  EVENT_DETAILS_CONFIG_URL,
  YOUTUBE_GALLERY_DETAILS_CONFIG_URL,

  GALLERY_DETAILS_CONFIG_URL,
  LOGIN_USING_COOKIE_URL,
};
