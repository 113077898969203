const fileTypeChecker = (file) =>
  new Promise((resolve) => {
    const fileReader = new FileReader();

    fileReader.onloadend = (e) => {
      const arr = new Uint8Array(e.target.result).subarray(0, 4);
      let header = "";
      let type = null;
      for (let i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }

      // console.log({ header });
      switch (header) {
        case "89504e47": // png
        case "ffd8ffe0": // following are jpeg magic numbers
        case "ffd8ffe1":
        case "ffd8ffdb":
        case "ffd8ffe2":
        case "ffd8ffe3":
        case "ffd8ffe8":
        case "52494646": //webp format
          type = "IMG";
          break;
        case "d0cf11e0": // xls
        case "504b34": // xlsx
          type = "XLS";
          break;
        case "25504446":
          type = "PDF";
          break;
        default: // Or you can use the blob.type as fallback
          type = "unknown";
          break;
      }
      resolve(type);

      return type;
    };

    fileReader.readAsArrayBuffer(file?.slice(0, 4));
  });

///

//   const message = "";
//   const contenttype = "";
//   let isValid = false;
//   let response = {};
//   switch (magicCheck) {
//     case "FF-D8-FF-E1":
//       contenttype = "image/jpg";
//       break;
//     case "89-50-4E-47":
//       contenttype = "image/png";
//       break;
//     case "FF-D8-FF-E0":
//       contenttype = "image/jpeg";
//       break;
//     case "FF-D8-FF-E1":
//       contenttype = "image/jpeg";
//       break;
//     case "25-50-44-46":
//       contenttype = "text/pdf";
//       break;
//   }
//   if (contenttype !== "") {
//     message = "Uploaded File is of type " + contenttype;
//     isValid = true;
//   } else {
//     message = "Please Upload Valid File with Original extension";
//     isValid = false;
//   }
//   response = { isValid, message };

module.exports = { fileTypeChecker };
